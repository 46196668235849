import React from 'react';
import { Container, Row, Col, Carousel, Accordion, Card  } from 'react-bootstrap';
import { Bar, Line, Pie } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { FaWarehouse, FaSyncAlt, FaChartLine, FaDollarSign, FaExpand, FaShieldAlt } from 'react-icons/fa'; // Importing Font Awesome icons
import './InventoryManagement.css';

// Register the required components
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const features = [
    {
        title: 'Real-time Inventory Tracking',
        description: 'Stay updated with your stock levels at any time.',
        icon: <FaWarehouse size={50} />,
    },
    {
        title: 'Automated Reorder Points',
        description: 'Ensure you\'re always stocked with the right items.',
        icon: <FaSyncAlt size={50} />,
    },
    {
        title: 'Demand Forecasting',
        description: 'Predict future stock needs with accuracy.',
        icon: <FaChartLine size={50} />,
    },
    {
        title: 'Cost Efficiency',
        description: 'Reduce operational costs with optimized inventory levels.',
        icon: <FaDollarSign size={50} />,
    },
    {
        title: 'Operational Flexibility',
        description: 'Adapt swiftly to market changes and business needs.',
        icon: <FaExpand size={50} />,
    },
    {
        title: 'Enhanced Security',
        description: 'Protect your inventory data with advanced security measures.',
        icon: <FaShieldAlt size={50} />,
    },
];


// Splitting features into groups of three for the carousel
const groupedFeatures = features.reduce((result, feature, index) => {
    const groupIndex = Math.floor(index / 3);
    if (!result[groupIndex]) {
        result[groupIndex] = [];
    }
    result[groupIndex].push(feature);
    return result;
}, []);

const InventoryManagement = () => {
    const barChartData = {
        labels: ['Q1', 'Q2', 'Q3', 'Q4'],
        datasets: [{
            label: 'Inventory Turnover',
            data: [12, 19, 3, 5],
            backgroundColor: '#4A90E2',
            borderRadius: 5,
        }],
    };

    const lineChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [{
            label: 'Stock Levels',
            data: [100, 200, 150, 300, 250, 400],
            borderColor: '#FF9800',
            fill: false,
            tension: 0.4,
            borderWidth: 3,
        }],
    };

    const pieChartData = {
        labels: ['Electronics', 'Furniture', 'Clothing', 'Books'],
        datasets: [{
            data: [300, 50, 100, 150],
            backgroundColor: ['#3F51B5', '#8BC34A', '#FF5722', '#FFEB3B'],
            borderColor: '#ffffff',
            borderWidth: 2,
        }],
    };

    return (
        <Container fluid className="inventory-container">
            <Row className="text-center mb-5">
                <Col>
                    <h1 className="display-4 title">Inventory Management</h1>
                    <p className="lead description">Optimize your stock levels and streamline your supply chain with our AI-powered inventory management solution.</p>
                </Col>
            </Row>

            <div className="feature-carousel-wrapper slide-in">
                <Carousel className="feature-carousel" indicators={false}>
                    {groupedFeatures.map((group, idx) => (
                        <Carousel.Item key={idx}>
                            <Row className="justify-content-center">
                                {group.map((feature, index) => (
                                    <Col md={4} key={index}>
                                        <div className="feature-card">
                                            <div className="feature-icon">{feature.icon}</div>
                                            <h2>{feature.title}</h2>
                                            <p>{feature.description}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>

            <div className="text-center my-4">
                <h2>Experience unmatched efficiency and control over your stock levels with our advanced, AI-driven tools.</h2>
            </div>

            <Row className="mb-5 charts-section">
                <Col md={6} className="mb-4">
                    <div className="chart-container">
                        <Bar data={barChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
                <Col md={6} className="mb-4">
                    <div className="chart-container">
                        <Line data={lineChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
            </Row>

            <Row className="mb-5">
                <Col md={6} className="mb-4 offset-md-3">
                    <div className="chart-container">
                        <Pie data={pieChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
            </Row>

            <Row className="text-center benefits-section">
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Cost Efficiency</h3>
                        <p>Reduce operational costs with optimized inventory levels.</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Time Savings</h3>
                        <p>Automate manual processes to save valuable time.</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Scalability</h3>
                        <p>Scale your operations efficiently with robust management tools.</p>
                    </div>
                </Col>
            </Row>

            <Row className="faq-section slide-in">
                <Col>
                    <h2 className="text-center mb-4">Frequently Asked Questions</h2>
                    <Accordion defaultActiveKey="0" className="faq-accordion">
                        {[
                            { question: "How does real-time inventory tracking work?", answer: "Our system uses advanced IoT sensors and cloud technology to provide up-to-the-minute updates on your inventory levels across all locations." },
                            { question: "Can I integrate this with my existing ERP system?", answer: "Yes, our solution offers seamless integration with most popular ERP systems, ensuring a smooth transition and data consistency." },
                            { question: "How accurate is the demand forecasting?", answer: "Our AI-powered demand forecasting typically achieves 95%+ accuracy by analyzing historical data, market trends, and various external factors." },
                            { question: "Is the system scalable for large enterprises?", answer: "Absolutely. Our system is designed to handle inventory management for businesses of all sizes, from small startups to large multinational corporations." }
                        ].map((faq, index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                                <Accordion.Header>{faq.question}</Accordion.Header>
                                <Accordion.Body>{faq.answer}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
};

export default InventoryManagement;
