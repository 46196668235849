import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { AiOutlineUsergroupAdd, AiOutlineShoppingCart, AiOutlineCloudSync, AiOutlineSync, AiOutlineFileText, AiOutlineInteraction } from 'react-icons/ai';

const FeaturesSectionWrapper = styled.div`
  background: #f8f9fa;
  padding: 1rem 0;
`;

const Title = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
  color: #333;
`;

const FeatureItem = styled(motion.div)`
  background: #ffffff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.05);
  height: 100%;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 15px 35px rgba(0, 0, 0, 0.1);
  }
`;

const FeatureIcon = styled.div`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #007bff;
`;

const FeatureTitle = styled.h3`
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
`;

const FeatureDescription = styled.p`
  font-size: 0.9rem;
  color: #666;
  line-height: 1.6;
`;

const features = [
  { title: 'Real-time Inventory Tracking', description: 'Keep track of inventory levels with up-to-the-minute updates.', icon: <AiOutlineShoppingCart /> },
  { title: 'Customer Relationship Management', description: 'Manage and improve your interactions with customers.', icon: <AiOutlineUsergroupAdd /> },
  { title: 'AI Analytics', description: 'Leverage artificial intelligence to gain business insights.', icon: <AiOutlineCloudSync /> },
  { title: 'Cost Reduction Tools', description: 'Automate tasks and reduce operational costs efficiently.', icon: <AiOutlineSync /> },
  { title: 'Supply Chain Management', description: 'Optimize your supply chain with advanced tools.', icon: <AiOutlineFileText /> },
  { title: 'Automated Financial Reports', description: 'Generate accurate financial reports with minimal effort.', icon: <AiOutlineInteraction /> },
];

const Features = () => {
  return (
    <FeaturesSectionWrapper id="features">
      <Container>
        <Title
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          Key Features
        </Title>
        <Row xs={1} md={2} lg={3} className="g-4">
          {features.map((feature, index) => (
            <Col key={index}>
              <FeatureItem
                whileHover={{ scale: 1.05 }}
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <FeatureIcon>{feature.icon}</FeatureIcon>
                <FeatureTitle>{feature.title}</FeatureTitle>
                <FeatureDescription>{feature.description}</FeatureDescription>
              </FeatureItem>
            </Col>
          ))}
        </Row>
      </Container>
    </FeaturesSectionWrapper>
  );
};

export default Features;
