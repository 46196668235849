import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
import Features from '../features/Features';
import Pricing from '../pricing/Pricing';
import ContactUs from '../contact-us/ContactUs';
import IndustriesComponent from '../industries/Industries';
import CustomerQuotesComponent from '../customer-quotes/CustomerQuotes';
import './Home.css';
import Lottie from 'react-lottie';
import crmErpAnimation from './erp-animation.json';
import FAQSection from '../faq/faq';
import SolutionsSection from '../solutions/Solutions';
import DynamicBackground from '../solutions/DynamicBackground';
import Benefits from '../benefits/Benefits';
import HowItWorks from '../benefits/HowItWorks';

const HomePage = () => {
  return (
    <>
      <Container fluid>
        <Home />
        <Features />
        <IndustriesComponent />
        <Benefits />
        <HowItWorks />
        <FAQSection />
        <Pricing />
        <ContactUs />
        <Container className='mt-5'></Container>
      </Container>
    </>
  );
};

export default HomePage;

const scrollToSection = (sectionId) => {
  const section = document.getElementById(sectionId);
  if (section) {
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

const Home = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: crmErpAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <Container id="home" className="mt-5 home-container">
      <div className="home-page">
        <Container fluid className="hero-section py-5">
          <Row className="align-items-center">
            <Col md={6} className="text-center text-md-left">
              <h1 className="display-3">Transform Your Business with Raya AI</h1>
              <p className="lead">Empower your business with AI-driven solutions that deliver actionable insights and drive growth</p>
              <Button 
                variant="primary" 
                size="lg" 
                href="#features" 
                onClick={() => scrollToSection('features')}
                className="me-3"
              >
                Explore Features
              </Button>
              <Button 
                variant="outline-info" 
                size="lg" 
                href="/documentation"
              >
                Documentation
              </Button>
            </Col>
            <Col md={6} className="text-center">
              <Lottie options={defaultOptions} height={400} width={400} />
            </Col>
          </Row>
        </Container>
        <Container className="my-5">
          <Row>
            <Col md={4} className="mb-3">
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Streamlined Operations</Card.Title>
                  <Card.Text>Automate your inventory, sales, and procurement processes to save time and reduce errors.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-3">
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Insightful Analytics</Card.Title>
                  <Card.Text>Gain valuable insights into your business operations with AI-driven analytics and reporting.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
            <Col md={4} className="mb-3">
              <Card className="h-100 shadow-sm">
                <Card.Body>
                  <Card.Title>Comprehensive Integration</Card.Title>
                  <Card.Text>Seamlessly integrate with existing systems for a unified business management experience.</Card.Text>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Container>
  );
};