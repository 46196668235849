import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './RegisterSuccess.css'; // Import the CSS for the animation

export const RegisterSuccess = () => {
    
  const location = useLocation();
  const navigate = useNavigate();

  if (!location.state || !location.state.fromRegister) {
    navigate('/register');
    return null;
  }

  return (
    <div className="register-success">
      <div className="animation">
        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
          <path className="checkmark__check" fill="none" d="M14 27l10 10 14-14"/>
        </svg>
      </div>
      <h1>You're all set!</h1>
      <p>You can now go to the <Link to="/login">login page</Link> to log in and use the product.</p>
    </div>
  );
};
