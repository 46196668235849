import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { LoginPage } from './auth/login/LoginPage';
import { ComingSoon } from './website/notfound/NotFound';
import Home from './website/home/Home';
import AboutAndContact from './website/about/About';
import { Registration } from './website/register/Register';
import { RegisterSuccess } from './website/register-success/RegisterSuccess';
import { jwtDecode } from 'jwt-decode';
import { ForgotPassword } from './website/forgot-password/ForgotPassword';
import { ResetPassword } from './website/reset-password/ResetPassword';
import InventoryManagement from './website/inventory-management/InventoryManagement';
import FinanceAccounting from './website/finance-accounting/FinanceAccounting';
import SalesAndMarketing from './website/sales-marketing/SalesMarketing';
import OperationsManagement from './website/operations/Operations';
import ServiceManagement from './website/service-management/ServiceManagement';
import BusinessIntelligence from './website/business-intelligence/BusinessIntelligence';
import RayaTiles from './website/raya-tiles';

const decodeJWT = (token) => {
    try {
        return jwtDecode(token);
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

export const RayaRoutes = ({ isAuthenticated }) => {
    const [authenticatedSession, setAuthenticatedSession] = useState(false);

    useEffect(() => {
        const token = Cookies.get('authToken');
        if (token) {
            const decodedToken = decodeJWT(token);
            const currentTime = new Date().getTime() / 1000; // Get current time in seconds
            if (decodedToken && decodedToken.exp > currentTime) {
                setAuthenticatedSession(true);
            } else {
                setAuthenticatedSession(false);
            }
        } else {
            setAuthenticatedSession(false);
        }
        isAuthenticated(authenticatedSession);
    }, [authenticatedSession, isAuthenticated]);

    return (
        <Routes>
            <Route path="/login" element={<LoginPage authSession={authenticatedSession} onLogin={(status) => isAuthenticated(status)} />} />
            <Route path="/" element={<Home authSession={authenticatedSession} onLogin={(status) => isAuthenticated(status)} />} />
            <Route path='/contact-us' element={<AboutAndContact />} />
            <Route path='/register' element={<Registration />} />
            <Route path='/register-success' element={<RegisterSuccess />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password' element={<ResetPassword />} />
            <Route path='/inventory-management' element={<InventoryManagement />} />
            <Route path='/finance-accounting' element={<FinanceAccounting />} />
            <Route path='/sales-marketing' element={<SalesAndMarketing />} />
            <Route path='/operations' element={<OperationsManagement />} />
            <Route path='/service' element={<ServiceManagement />} />
            <Route path='/business-intelligence' element={<BusinessIntelligence />} />
            <Route path='/raya-products' element={<RayaTiles />} />
            <Route path="*" element={<ComingSoon />} />
        </Routes>
    );
};
