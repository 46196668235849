import React, { useEffect, useState } from 'react';
import { Assessment, Business } from '@mui/icons-material';
import { Box, Card } from '@mui/material';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { BI_APP_URI, DASHBOARD_APP_URI } from '../constants/constants';

export const RayaTiles = () => {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const getTokenFromUrl = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get('token');
  };

  const navigateUserBasedOnLicense = (tile) => {
    if (tile === 'BI') {
      window.location.href = `${BI_APP_URI}bi/dashboard?token=${localStorage.getItem('authToken') || Cookies.get('authToken')}`;
    } else if (tile === 'BS') {
      window.location.href = `${DASHBOARD_APP_URI}dashboard?token=${localStorage.getItem('authToken') || Cookies.get('authToken')}`;
    }
  };

  useEffect(() => {
    const tokenFromCookie = Cookies.get('authToken');
    const tokenFromUrl = getTokenFromUrl();

    if (tokenFromCookie) {
      setIsAuthenticated(true);
    } else if (tokenFromUrl) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
      navigate('/');
    }
  }, []);

  return (
    <>
      <Container
        fluid
        className="login-container"
        style={{
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {isAuthenticated ? (
          <div className="d-flex">
            <div className="d-flex w-full">
              <Card className="flex-grow-1 p-4 mx-4 hover:shadow-lg transition-shadow cursor-pointer">
                <div onClick={() => navigateUserBasedOnLicense('BS')} className="d-flex justify-content-center align-items-center gap-4">
                  <Box sx={{ borderRadius: '5px' }} className="p-3 bg-purple-100 rounded-lg">
                    <Business
                      sx={{
                        width: '35px',
                        height: '35px',
                      }}
                      className="w-6 h-6 text-purple-600"
                    />
                  </Box>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900">Business Suite</h3>
                    <p className="text-gray-600 mt-2">Complete toolkit for enterprise management</p>
                  </div>
                </div>
              </Card>
              <Card className="flex-grow-1 p-4 mx-4 hover:shadow-lg transition-shadow cursor-pointer">
                <div onClick={() => navigateUserBasedOnLicense('BI')} className="d-flex justify-content-center align-items-center gap-4">
                  <Box sx={{ borderRadius: '5px' }} className="p-3 bg-blue-100 rounded-lg">
                    <Assessment
                      className="text-blue-600"
                      sx={{
                        width: '35px',
                        height: '35px',
                      }}
                    />
                  </Box>
                  <div>
                    <h3 className="text-xl font-semibold text-gray-900">Business Intelligence</h3>
                    <p className="text-gray-600 mt-2">Analytics and insights for data-driven decisions</p>
                  </div>
                </div>
              </Card>
            </div>
          </div>
        ) : null}
      </Container>
    </>
  );
};

export default RayaTiles;
