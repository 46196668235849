import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Carousel, Accordion } from 'react-bootstrap';
import { Radar, PolarArea, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    BarElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { FaChartLine, FaBullhorn, FaUsers, FaRocket, FaHandshake, FaLightbulb } from 'react-icons/fa';
import './SalesAndMarketing.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    BarElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const features = [
    {
        title: 'Customer Analytics',
        description: 'Gain deep insights into customer behavior and preferences.',
        icon: <FaChartLine size={50} />,
    },
    {
        title: 'Multi-channel Marketing',
        description: 'Reach your audience across various platforms effectively.',
        icon: <FaBullhorn size={50} />,
    },
    {
        title: 'Lead Management',
        description: 'Efficiently track and nurture leads through the sales funnel.',
        icon: <FaUsers size={50} />,
    },
    {
        title: 'Campaign Automation',
        description: 'Automate and optimize your marketing campaigns for better ROI.',
        icon: <FaRocket size={50} />,
    },
    {
        title: 'Sales Pipeline Management',
        description: 'Visualize and manage your sales process from start to finish.',
        icon: <FaHandshake size={50} />,
    },
    {
        title: 'Predictive Analytics',
        description: 'Use AI to predict future trends and customer actions.',
        icon: <FaLightbulb size={50} />,
    },
];

const groupedFeatures = features.reduce((result, feature, index) => {
    const groupIndex = Math.floor(index / 3);
    if (!result[groupIndex]) {
        result[groupIndex] = [];
    }
    result[groupIndex].push(feature);
    return result;
}, []);

const SalesAndMarketing = () => {
    const [counts, setCounts] = useState({ leads: 0, conversions: 0, campaigns: 0 });

    useEffect(() => {
        // Simulating count animation
        let leadCount = 0;
        let conversionCount = 0;
        let campaignCount = 0;

        const interval = setInterval(() => {
            leadCount = Math.min(leadCount + Math.floor(Math.random() * 10), 350);
            conversionCount = Math.min(conversionCount + Math.floor(Math.random() * 5), 300);
            campaignCount = Math.min(campaignCount + Math.floor(Math.random() * 3), 150);

            setCounts({ leads: leadCount, conversions: conversionCount, campaigns: campaignCount });

            if (leadCount >= 350 && conversionCount >= 300 && campaignCount >= 150) {
                clearInterval(interval);
            }
        }, 50);

        return () => clearInterval(interval);
    }, []);

    const radarChartData = {
        labels: ['Reach', 'Engagement', 'Conversions', 'Retention', 'LTV'],
        datasets: [{
            label: 'Marketing Performance Metrics',
            data: [75, 85, 65, 90, 80],
            backgroundColor: 'rgba(76, 175, 80, 0.2)',
            borderColor: '#4CAF50',
            pointBackgroundColor: '#4CAF50',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#4CAF50',
        }],
    };

    const polarAreaChartData = {
        labels: ['Email', 'Social Media', 'PPC', 'SEO', 'Content'],
        datasets: [{
            data: [65, 59, 80, 81, 56],
            backgroundColor: ['#FF5722', '#8BC34A', '#FFC107', '#03A9F4', '#E91E63'],
            borderColor: '#ffffff',
            borderWidth: 2,
        }],
    };

    const barChartData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [{
            label: 'Monthly Sales Performance',
            data: [15000, 18000, 17500, 20000, 22000, 24000],
            backgroundColor: '#4A90E2',
            borderRadius: 5,
        }],
    };

    return (
        <Container fluid className="sales-marketing-container">
            <Row className="text-center mb-5 fade-in">
                <Col>
                    <h1 className="display-4 title">Sales & Marketing</h1>
                    <p className="lead description">Boost your revenue and streamline your sales process with our AI-enhanced sales and marketing tools.</p>
                </Col>
            </Row>

            <Row className="text-center mb-5 counts-section slide-in">
                <Col md={4} className="mb-4">
                    <div className="count-item">
                        <h2>{counts.leads}</h2>
                        <p>Leads Generated</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="count-item">
                        <h2>{counts.conversions}</h2>
                        <p>Conversions</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="count-item">
                        <h2>{counts.campaigns}</h2>
                        <p>Campaigns Launched</p>
                    </div>
                </Col>
            </Row>

            <div className="feature-carousel-wrapper slide-in">
                <Carousel className="feature-carousel" indicators={false}>
                    {groupedFeatures.map((group, idx) => (
                        <Carousel.Item key={idx}>
                            <Row className="justify-content-center">
                                {group.map((feature, index) => (
                                    <Col md={4} key={index}>
                                        <div className="feature-card">
                                            <div className="feature-icon">{feature.icon}</div>
                                            <h2>{feature.title}</h2>
                                            <p>{feature.description}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>

            <div className="text-center my-4 fade-in">
                <h2>Harness the power of data-driven marketing and AI-assisted sales strategies.</h2>
            </div>

            <Row className="mb-5 charts-section slide-in">
                <Col md={6} className="mb-4">
                    <div className="chart-container">
                        <Radar data={radarChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
                <Col md={6} className="mb-4">
                    <div className="chart-container">
                        <PolarArea data={polarAreaChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
            </Row>

            <Row className="mb-5 slide-in">
                <Col md={6} className="mb-4 offset-md-3">
                    <div className="chart-container">
                        <Bar data={barChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
            </Row>

            <Row className="text-center benefits-section fade-in">
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Increased Conversions</h3>
                        <p>Improve your conversion rates with targeted marketing and efficient sales processes.</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Customer Retention</h3>
                        <p>Enhance customer loyalty through personalized engagement and superior service.</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Data-Driven Decisions</h3>
                        <p>Make informed decisions based on real-time data and predictive analytics.</p>
                    </div>
                </Col>
            </Row>

            <Row className="faq-section slide-in">
                <Col>
                    <h2 className="text-center mb-4">Frequently Asked Questions</h2>
                    <Accordion defaultActiveKey="0" className="faq-accordion">
                        {[
                            { question: "How does AI enhance marketing campaigns?", answer: "Our AI algorithms analyze customer data to optimize campaign targeting, timing, and content, resulting in higher engagement and conversion rates." },
                            { question: "Can I integrate this with my CRM system?", answer: "Yes, our solution offers seamless integration with popular CRM systems, ensuring a unified view of your customer interactions and sales processes." },
                            { question: "How does the lead scoring feature work?", answer: "Our AI-powered lead scoring system evaluates leads based on multiple factors like engagement, demographics, and behavior to prioritize the most promising prospects." },
                            { question: "Is the platform suitable for B2B marketing?", answer: "Absolutely. Our platform is versatile and can be customized for both B2B and B2C marketing strategies, with features tailored to longer sales cycles and complex decision-making processes." }
                        ].map((faq, index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                                <Accordion.Header>{faq.question}</Accordion.Header>
                                <Accordion.Body>{faq.answer}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
};

export default SalesAndMarketing;
