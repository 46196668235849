import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { motion } from 'framer-motion';

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
`;

const Container = styled.main`
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 20px;
  background: linear-gradient(to left, #5a94e6, #c4d5f4);
  color: #333;
`;

const Title = styled(motion.h1)`
  font-size: 3rem;
  margin-bottom: 1rem;
`;

const Subtitle = styled(motion.p)`
  font-size: 1.5rem;
  margin-bottom: 2rem;
`;

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`;

const CountdownItem = styled.div`
  margin: 0 1rem;
`;

const CountdownNumber = styled.div`
  font-size: 3rem;
  font-weight: bold;
`;

const CountdownLabel = styled.div`
  font-size: 0.9rem;
  text-transform: uppercase;
`;

const PulsingDot = styled.div`
  width: 20px;
  height: 20px;
  background-color: #4fd1c5;
  border-radius: 50%;
  animation: ${pulse} 1.5s infinite ease-in-out;
`;

export const ComingSoon = () => {
  const [timeLeft, setTimeLeft] = useState({});

  useEffect(() => {
    const calculateTimeLeft = () => {
      const difference = +new Date("2023-12-31") - +new Date();
      let timeLeft = {};

      if (difference > 0) {
        timeLeft = {
          days: Math.floor(difference / (1000 * 60 * 60 * 24)),
          hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
          minutes: Math.floor((difference / 1000 / 60) % 60),
          seconds: Math.floor((difference / 1000) % 60)
        };
      }

      return timeLeft;
    };

    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const timerComponents = Object.keys(timeLeft).map((interval) => (
    <CountdownItem key={interval}>
      <CountdownNumber>{timeLeft[interval]}</CountdownNumber>
      <CountdownLabel>{interval}</CountdownLabel>
    </CountdownItem>
  ));

  return (
    <Container>
      <Title
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Coming Soon
      </Title>
      <Subtitle
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        We're working hard to bring you something amazing
      </Subtitle>
    
        Stay tuned for updates!
    </Container>
  );
};
