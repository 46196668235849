import React from 'react';
import { Container, Row, Col, Card, ListGroup, Button } from 'react-bootstrap';
import './Pricing.css'; // Make sure to import your CSS file

const pricingPlans = [
  { name: 'Business Suite', price: 'Free', features: ['Unlimited users', 'Core CRM & ERP Functionalities', 'Self-Service Support'], color: '#dc3545' }, // Green
  { name: 'Business Intelligence', price: '$5 per user/month', features: ['Unlimited users', 'Advanced analytics', 'Dedicated Support'], color: '#007bff' }, // Blue
  { name: 'Business Suite & Intelligence', price: '$10 per user/month', features: ['Unlimited users', 'AI analytics', 'Custom Development and Consultation'], color: '#28a745' }, // Red
];

const Pricing = () => {
  return (
    <Container className="mt-5" id="pricing">
      <h2 className="text-center mb-4">Pricing Plans</h2>
      <Row>
        {pricingPlans.map((plan, index) => (
          <Col md={4} key={index}>
            <Card className="mb-3">
              <Card.Header as="h5" style={{ backgroundColor: plan.color, color: 'white' }}>
                {plan.name}
              </Card.Header>
              <Card.Body>
                {/* <Card.Title className="text-center">{plan.price}</Card.Title> */}
                <ListGroup variant="flush">
                  {plan.features.map((feature, i) => (
                    <ListGroup.Item key={i}>{feature}</ListGroup.Item>
                  ))}
                </ListGroup>
                <Button variant="outline-grey" className="mt-3" style={{ backgroundColor: plan.color, borderColor: plan.color }}>
                  Choose Plan
                </Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Pricing;
