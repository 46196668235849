import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FaUserPlus, FaListUl, FaCog, FaRocket } from 'react-icons/fa';

const HowItWorks = () => {
  const steps = [
    { icon: <FaUserPlus />, title: "1. Sign Up Online", description: "Create your account by signing up on our platform." },
    { icon: <FaListUl />, title: "2. Select a Plan", description: "Choose the plan that best fits your business needs." },
    { icon: <FaCog />, title: "3. Guided Setup", description: "Go through our guided tour to set up your business preferences." },
    { icon: <FaRocket />, title: "4. Get Started", description: "Start using the platform and enjoy our features with ongoing support." },
  ];

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4">How It Works</h2>
      <Row>
        {steps.map((step, idx) => (
          <Col key={idx} md={3} className="mb-4">
            <div className="text-center">
              <div className="step-icon mb-3" style={{ fontSize: '2.5rem', color: '#28a745' }}>
                {step.icon}
              </div>
              <h4>{step.title}</h4>
              <p>{step.description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default HowItWorks;
