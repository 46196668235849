import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { FaUser, FaBook, FaWarehouse, FaChartLine, FaCog, FaDollarSign, FaWrench, FaLightbulb } from 'react-icons/fa';
import brandImage from './Brand.png';
import './UnAuthHeader.css';

export const UnAuthHeader = () => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToSection = (sectionId) => {
    const navbar = document.querySelector('.navbar');
    const navbarHeight = navbar.offsetHeight;
    const section = document.getElementById(sectionId);
    if (section) {
      const offsetTop = section.offsetTop - navbarHeight - 50; // Subtracting an extra 50px for additional spacing
      window.scrollTo({
        top: offsetTop,
        behavior: 'smooth',
      });
    }
  };

  const handleBrandClick = () => {
    setExpanded(false);
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: 'home' } });
    } else {
      scrollToSection('home');
    }
  };

  const handleNavItemClick = (sectionId, event) => {
    event.preventDefault();
    setExpanded(false);
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: sectionId } });
    } else {
      scrollToSection(sectionId);
    }
  };

  const handleDropdownItemClick = (path) => {
    setExpanded(false);
    navigate(path);
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: '10px',
      backgroundColor: 'white', // Light grey background
      width: '100vw',
    },
    brandImage: {
      maxHeight: '30px', // Adjust as needed
      maxWidth: '120px', // Adjust as needed
      objectFit: 'contain', // Ensure the image scales properly
    },
    navLink: {
      color: '#333', // Consistent dark color for all nav links
      fontWeight: '300', // Consistent font weight
    },
    navDropdown: {
      color: '#333', // Consistent dark color for dropdown items
      fontWeight: '300', // Consistent font weight
    },
    dropdownItem: {
      display: 'flex',
      alignItems: 'center',
      padding: '10px 15px', // Adjust padding as needed
    },
    dropdownIcon: {
      marginRight: '10px', // Space between icon and text
    },
  };

  return (
    <Navbar expand="lg" fixed="top" id="navbar" expanded={expanded} style={styles.container}>
      <Container>
        <Navbar.Brand onClick={handleBrandClick}>
          <img src={brandImage} alt="Brand" style={styles.brandImage} />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={() => setExpanded(!expanded)} />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link onClick={(event) => handleNavItemClick('features', event)} style={styles.navLink}>
              Features
            </Nav.Link>
            <NavDropdown title="Solutions" id="basic-nav-dropdown" style={styles.navDropdown}>
              {/* <NavDropdown.Item onClick={() => handleDropdownItemClick('/inventory-management')} style={styles.dropdownItem}>
                <FaWarehouse style={styles.dropdownIcon} /> Inventory Management
              </NavDropdown.Item> */}
              {/* <NavDropdown.Item onClick={() => handleDropdownItemClick('/sales-marketing')} style={styles.dropdownItem}>
                <FaChartLine style={styles.dropdownIcon} /> Sales & Marketing
              </NavDropdown.Item> */}
              {/* <NavDropdown.Item onClick={() => handleDropdownItemClick('/operations')} style={styles.dropdownItem}>
                <FaCog style={styles.dropdownIcon} /> Operations
              </NavDropdown.Item> */}
              {/* <NavDropdown.Item onClick={() => handleDropdownItemClick('/finance-accounting')} style={styles.dropdownItem}>
                <FaDollarSign style={styles.dropdownIcon} /> Finance & Accounting
              </NavDropdown.Item> */}
              <NavDropdown.Item onClick={() => handleDropdownItemClick('/service')} style={styles.dropdownItem}>
                <FaWrench style={styles.dropdownIcon} /> Business Suite
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => handleDropdownItemClick('/business-intelligence')} style={styles.dropdownItem}>
                <FaLightbulb style={styles.dropdownIcon} /> Business Intelligence
              </NavDropdown.Item>
            </NavDropdown>
            <Nav.Link onClick={(event) => handleNavItemClick('pricing', event)} style={styles.navLink}>
              Pricing
            </Nav.Link>
            <Nav.Link onClick={() => { setExpanded(false); navigate('/documentation'); }} style={styles.navLink}>
              Documentation
            </Nav.Link>
            <Nav.Link onClick={() => { setExpanded(false); navigate('/contact-us'); }} style={styles.navLink}>
              Contact Us
            </Nav.Link>
          </Nav>
          <Nav>
            <Button variant="outline-primary" size="sm" onClick={() => { setExpanded(false); navigate('/login'); }}>
              <FaUser /> Login
            </Button>
            <Button variant="success" size="sm" onClick={() => { setExpanded(false); navigate('/register'); }}>
              Try for free
            </Button>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
