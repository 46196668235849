import React from 'react';
import { Container } from 'react-bootstrap';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { MdBuild, MdLocalHospital, MdShoppingCart, MdAccountBalance, MdSchool, MdComputer } from 'react-icons/md';

const StyledContainer = styled(Container)`
  padding: 4rem 0;
  background: linear-gradient(135deg, #f6f9fc 0%, #e9f1f7 100%);
  border-radius: 30px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
  overflow: hidden;
`;

const Title = styled(motion.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
  color: #2c3e50;
`;

const IndustriesGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 2rem;
  padding: 0 2rem;
`;

const IndustryItem = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  color: #3498db;
  background: white;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 10px 20px rgba(52, 152, 219, 0.2);
  margin-bottom: 1rem;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-5px) rotate(10deg);
    box-shadow: 0 15px 30px rgba(52, 152, 219, 0.3);
  }
`;

const IndustryName = styled.h4`
  font-size: 1.1rem;
  font-weight: 600;
  color: #34495e;
  margin: 0;
`;

const industries = [
  { name: 'Manufacturing', icon: <MdBuild /> },
  { name: 'Healthcare', icon: <MdLocalHospital /> },
  { name: 'Retail', icon: <MdShoppingCart /> },
  { name: 'Finance', icon: <MdAccountBalance /> },
  { name: 'Education', icon: <MdSchool /> },
  { name: 'Technology', icon: <MdComputer /> },
];

const IndustriesComponent = () => {
  return (
    <StyledContainer fluid id="industries">
      <Title
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Empowering Industries Across the Spectrum
      </Title>
      <IndustriesGrid
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        {industries.map((industry, idx) => (
          <IndustryItem
            key={idx}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: idx * 0.1 }}
          >
            <IconWrapper>{industry.icon}</IconWrapper>
            <IndustryName>{industry.name}</IndustryName>
          </IndustryItem>
        ))}
      </IndustriesGrid>
    </StyledContainer>
  );
};

export default IndustriesComponent;