import React from 'react';
import { Container, Row, Col, Carousel, Accordion } from 'react-bootstrap';
import { Doughnut, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { FaCog, FaShippingFast, FaIndustry, FaClipboardList, FaBoxes, FaChartLine } from 'react-icons/fa';
import './OperationsManagement.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const features = [
    {
        title: 'Supply Chain Management',
        description: 'Optimize and automate your supply chain processes.',
        icon: <FaShippingFast size={50} />,
    },
    {
        title: 'Production Planning',
        description: 'Ensure efficient resource allocation and production scheduling.',
        icon: <FaIndustry size={50} />,
    },
    {
        title: 'Quality Control & Packaging',
        description: 'Maintain high quality standards and streamline packaging processes.',
        icon: <FaBoxes size={50} />,
    },
    {
        title: 'Order Fulfillment',
        description: 'Streamline the process of fulfilling customer orders with precision.',
        icon: <FaClipboardList size={50} />,
    },
    {
        title: 'Operational Analytics',
        description: 'Gain insights into operational performance with real-time data.',
        icon: <FaChartLine size={50} />,
    },
    {
        title: 'Maintenance Scheduling',
        description: 'Plan and manage maintenance to minimize downtime.',
        icon: <FaCog size={50} />,
    },
];

const groupedFeatures = features.reduce((result, feature, index) => {
    const groupIndex = Math.floor(index / 3);
    if (!result[groupIndex]) {
        result[groupIndex] = [];
    }
    result[groupIndex].push(feature);
    return result;
}, []);

const OperationsManagement = () => {
    const barChartData = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
        datasets: [{
            label: 'Production Output',
            data: [120, 150, 130, 170, 180, 210],
            backgroundColor: '#4A90E2',
            borderRadius: 5,
        }],
    };

    const doughnutChartData = {
        labels: ['Manufacturing', 'Warehousing', 'Transportation', 'Maintenance'],
        datasets: [{
            data: [300, 150, 200, 100],
            backgroundColor: ['#FF5722', '#8BC34A', '#FFC107', '#03A9F4'],
            borderColor: '#ffffff',
            borderWidth: 2,
        }],
    };

    const horizontalBarChartData = {
        labels: ['Efficiency', 'Cost', 'Quality', 'Flexibility', 'Delivery'],
        datasets: [{
            label: 'Operational Performance Metrics',
            data: [80, 70, 90, 85, 75],
            backgroundColor: '#8BC34A',
            borderRadius: 5,
        }],
    };

    const horizontalBarOptions = {
        indexAxis: 'y',  // This option makes the bar chart horizontal
        responsive: true,
        maintainAspectRatio: false,
    };

    return (
        <Container fluid className="operations-container">
            <Row className="text-center mb-5">
                <Col>
                    <h1 className="display-4 title">Operations Management</h1>
                    <p className="lead description">Streamline and optimize your operational processes with our advanced tools.</p>
                </Col>
            </Row>

            <div className="feature-carousel-wrapper slide-in">
                <Carousel className="feature-carousel" indicators={false}>
                    {groupedFeatures.map((group, idx) => (
                        <Carousel.Item key={idx}>
                            <Row className="justify-content-center">
                                {group.map((feature, index) => (
                                    <Col md={4} key={index}>
                                        <div className="feature-card">
                                            <div className="feature-icon">{feature.icon}</div>
                                            <h2>{feature.title}</h2>
                                            <p>{feature.description}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>

            <div className="text-center my-4">
                <h2>Boost operational efficiency and drive growth with our comprehensive operations management solutions.</h2>
            </div>

            <Row className="mb-5 charts-section">
                <Col md={6} className="mb-4">
                    <div className="chart-container">
                        <Doughnut data={doughnutChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
                <Col md={6} className="mb-4">
                    <div className="chart-container">
                        <Bar data={horizontalBarChartData} options={horizontalBarOptions} />
                    </div>
                </Col>
            </Row>

            <Row className="mb-5">
                <Col md={6} className="mb-4 offset-md-3">
                    <div className="chart-container">
                        <Bar data={barChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
            </Row>

            <Row className="text-center benefits-section">
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Increased Efficiency</h3>
                        <p>Enhance productivity with streamlined operational processes.</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Cost Reduction</h3>
                        <p>Reduce operational costs through better resource management.</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Improved Quality</h3>
                        <p>Maintain high quality standards while increasing throughput.</p>
                    </div>
                </Col>
            </Row>

            <Row className="faq-section slide-in">
                <Col>
                    <h2 className="text-center mb-4">Frequently Asked Questions</h2>
                    <Accordion defaultActiveKey="0" className="faq-accordion">
                        {[
                            { question: "How can I automate supply chain tasks?", answer: "Our platform provides tools to automate various supply chain tasks, such as inventory management, order processing, and shipment tracking." },
                            { question: "Can I integrate this with my existing ERP system?", answer: "Yes, our solution offers seamless integration with popular ERP systems, ensuring a unified view of your operations." },
                            { question: "How does the quality control feature work?", answer: "Our quality control tools help you maintain high standards throughout your production process, while our packaging solutions ensure your products are ready for delivery with minimal waste." },
                            { question: "Is the system scalable for large enterprises?", answer: "Absolutely. Our operations management solution is designed to scale with your business, accommodating growing demands and complex operations." }
                        ].map((faq, index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                                <Accordion.Header>{faq.question}</Accordion.Header>
                                <Accordion.Body>{faq.answer}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
};

export default OperationsManagement;
