// Branding
export const BRAND_NAME = 'Raya AI';

// API Endpoints
export const API_BASE_URL = process.env.REACT_APP_IDS_API_BASE_URL;
export const API_ACCOUNT_REGISTER_ENDPOINT = '/RayaAccountManagement/Register';
export const API_AUTH_ENDPOINT = '/Auth';
export const API_CREDENTIALS_ENDPOINT = '/Credentials';
export const API_RESET_PASSWORD = '/ResetPassword';
export const API_AUTH_RENEW_TOKEN = '/RenewSession';
export const API_VERIFY_MFA_ENDPOINT = '/verify-mfa';
export const API_FORGOT_PASSWORD = '/forgot-password';

// URIs
export const WEBSITE_URI = process.env.REACT_APP_WEBSITE_URL;
export const DASHBOARD_APP_URI = process.env.REACT_APP_DASHBOARD_URL;
export const BI_APP_URI = process.env.REACT_APP_BI_URL;

// domains
export const DOMAIN = process.env.DOMAIN;

// Claims
export const TOKEN_EMAIL_CLAIMS = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress';
export const TOKEN_ACCOUNT_NAME_CLAIMS = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/windowsaccountname';
export const TOKEN_USER_ROLE = 'http://schemas.microsoft.com/ws/2008/06/identity/claims/role';
export const TOKEN_USER_GIVEN_NAME = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname';

// Colors
export const PRIMARY_COLOR = '#007bff';
export const SECONDARY_COLOR = '#6c757d';

// Messages
export const ERROR_MESSAGE = 'An error occurred. Please try again later.';

// User Roles
export const USER_ROLES = {
  ADMIN: 'admin',
  USER: 'user',
};
