import React from 'react';
import { Container, Row, Col, Form, Button, Card } from 'react-bootstrap';
import './About.css';

const AboutAndContact = () => {
  return (
    <Container fluid className="mt-5 about-contact-container">
      <Row className="justify-content-center mb-5">
        <Col lg={8} md={10} className="about-section">
          <h2 className="about-header">About Raya</h2>
          <p className="about-text">Raya Suite offers a comprehensive set of Enterprise Resource Planning (ERP) and Customer Relationship Management (CRM) functionalities to effectively manage all aspects of your business. Whether you're overseeing inventory, finances, procurement, sales, or operations, our platform is designed to streamline every business function. Even if you're not using Raya ERP and CRM, you can still leverage Raya's powerful Business Intelligence (BI) to gain valuable insights and make informed decisions. With Raya Suite, you'll optimize workflows, enhance decision-making processes, and set your business up for long-term success.</p>
          <p className="about-text">Since our founding in 2023, we've been dedicated to developing a platform that meets businesses' current needs and anticipates future challenges. Our commitment to innovation and excellence has made us an industry leader.</p>
          <p className="about-text">We welcome any inquiries, suggestions, or feedback. Please reach out to us through the form below, or contact us directly via email or phone.</p>
        </Col>
        <Col lg={7} md={10} sm={12} className="contact-section">
          <Card className="contact-card">
            <Card.Body>
              <h2 className="contact-header text-center">Contact Us</h2>
              <Form>
                <Form.Group className="mb-3">
                  <Form.Label>Name</Form.Label>
                  <Form.Control type="text" placeholder="Enter your name" />
                </Form.Group>
                <Row className="mb-3">
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Company Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter your company name" />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group>
                      <Form.Label>Designation</Form.Label>
                      <Form.Control type="text" placeholder="Enter your designation" />
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group className="mb-3">
                  <Form.Label>Region</Form.Label>
                  <Form.Control as="select">
                    <option>Choose...</option>
                    <option>North America</option>
                    <option>Europe</option>
                    <option>Asia</option>
                    <option>Australia</option>
                    <option>South America</option>
                    <option>Africa</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Email address</Form.Label>
                  <Form.Control type="email" placeholder="Enter email" />
                  <Form.Text className="text-muted">
                    We'll never share your email with anyone else.
                  </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Message</Form.Label>
                  <Form.Control as="textarea" rows={4} />
                </Form.Group>
                <div className="text-center">
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </Form>
              <div className="contact-options text-center mt-4">
                <p>Email us at <a href="mailto:contact@raya.com">contact@rayaaisuite.com</a></p>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AboutAndContact;
