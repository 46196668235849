import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './Register.css';
import {
  Container,
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Card,
  CardContent,
  CardHeader,
} from '@mui/material';
import countries from 'i18n-iso-countries';
import english from 'i18n-iso-countries/langs/en.json';
import { API_ACCOUNT_REGISTER_ENDPOINT, API_AUTH_ENDPOINT, API_BASE_URL, API_VERIFY_MFA_ENDPOINT, BI_APP_URI, DASHBOARD_APP_URI, DOMAIN } from '../../constants/constants';
import Cookies from 'js-cookie';
import styled from 'styled-components';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

export const Registration = () => {
  const RegisterEndpoint = API_BASE_URL + API_ACCOUNT_REGISTER_ENDPOINT;
  const VerifyMfaEndpoint = API_BASE_URL + API_AUTH_ENDPOINT + API_VERIFY_MFA_ENDPOINT;
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState('');
  const [companySize, setCompanySize] = useState('');
  const [location, setLocation] = useState('');
  const [license, setLicense] = useState('');
  const [userName, setUserName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [signInEmail, setSignInEmail] = useState('');
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const companySizes = ['1', '2-20', '20-100', '100-500', '500+'];
  const allCountries = Object.values(countries.getNames('en'));
  const [emailError, setEmailError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [signInEmailError, setSignInEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [isLowercase, setIsLowercase] = useState(false);
  const [isUppercase, setIsUppercase] = useState(false);
  const [isDigit, setIsDigit] = useState(false);
  const [isSpecialChar, setIsSpecialChar] = useState(false);
  const [isLengthValid, setIsLengthValid] = useState(false);
  const [qrCodeImage, setQrCodeImage] = useState('');
  const [isPasswordPopupOpen, setIsPasswordPopupOpen] = useState(false);

  countries.registerLocale(english);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const phoneRegex = /^\d{10}$/;
  const strongPasswordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,15}$/;

  useEffect(() => {
    setIsLowercase(/[a-z]/.test(password));
    setIsUppercase(/[A-Z]/.test(password));
    setIsDigit(/\d/.test(password));
    setIsSpecialChar(/[^\da-zA-Z]/.test(password));
    setIsLengthValid(password.length >= 8 && password.length <= 15);
  }, [password]);

  const regTabs = [
    { label: 'Company Details', value: 1 },
    { label: 'User Details', value: 2 },
    { label: 'License', value: 3 },
    { label: 'Verification', value: 4 },
    { label: 'Scan QR Code', value: 5 },
  ];

  const licenseProducts = [
    {
      label: `Business Suite & Intelligence`,
      value: 0,
      bgcolor: 'rgb(40, 167, 69)',
      features: ['Unlimited users', 'AI analytics', 'Custom Development and Consultation'],
    },
    {
      label: `Business Suite`,
      value: 1,
      bgcolor: 'rgb(220, 53, 69)',
      features: ['Unlimited users', 'Core CRM & ERP Functionalities', 'Self-Service Support'],
    },
    {
      label: `Business Intelligence`,
      value: 2,
      bgcolor: 'rgb(0, 123, 255)',
      features: ['Unlimited users', 'Advanced analytics', 'Dedicated Support'],
    },
  ];

  // const licenseProducts = ['Business Suite', 'Business Intelligence', 'Business Suite & Intelligence'];

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailError(!emailRegex.test(e.target.value));
  };

  const handlePhoneChange = (e) => {
    setPhone(e.target.value);
    setPhoneError(!phoneRegex.test(e.target.value));
  };

  const handleSignInEmailChange = (e) => {
    setSignInEmail(e.target.value);
    setSignInEmailError(!emailRegex.test(e.target.value));
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordError(!strongPasswordRegex.test(e.target.value));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!captchaVerified) {
      alert('Please verify the CAPTCHA');
      return;
    }

    if (!phoneRegex.test(phone)) {
      alert('Please enter a valid phone number');
      return;
    }

    const formData = {
      CompanyName: companyName,
      CompanySize: parseInt(companySize),
      Country: location,
      AdminEmailAddress: signInEmail,
      JobTitle: jobTitle,
      PhoneNumber: phone,
      FullName: userName,
      AdminSignInEmailAddress: signInEmail,
      AdminPassword: password,
      license: license,
    };

    const jsonData = JSON.stringify(formData);

    try {
      const response = await fetch(RegisterEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: jsonData,
      });

      if (!response.ok) {
        if (response.status === 400) {
          const errorText = await response.text();
          setErrorMessage(errorText);
        } else {
          setErrorMessage('An error occurred while registering. Please try again later.');
        }
      } else {
        const responseData = await response.json();
        setQrCodeImage(responseData.qrCodeImageAsBase64);
        setStep(5);
      }
    } catch (error) {
      console.error('An error occurred while registering:', error);
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(true);
  };

  const decodeJWT = (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  const handleVerifyCode = async (code) => {
    try {
      const response = await fetch(VerifyMfaEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          emailAddress: signInEmail,
          totpCode: code,
        }),
      });

      if (response.ok) {
        const { token } = await response.json();
        Cookies.set('authToken', token, { domain: DOMAIN, secure: true, sameSite: 'None', path: '/' });
        const decodedToken = decodeJWT(token);
        if (decodedToken?.RayaLicense === 'BIAndBS') {
          navigate('raya-products');
        } else if (decodedToken?.RayaLicense === 'BI') {
          window.location.href = `${BI_APP_URI}?token=${token}`;
        } else if (decodedToken?.RayaLicense === 'BS') {
          window.location.href = `${DASHBOARD_APP_URI}dashboard?token=${token}`;
        }
      } else {
        alert('Invalid code. Please try again.');
      }
    } catch (error) {
      console.error('An error occurred while verifying the code:', error);
      alert('An error occurred. Please try again later.');
    }
  };

  const StyledCard = styled(Card)(({ theme, isSelected }) => ({
    cursor: 'pointer',
    minHeight: '250px',
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    // transition: 'transform 0.2s ease-in-out',
    // transform: isSelected ? 'scale(1.05)' : 'scale(1)',
    // '&:hover': {
    //   transform: 'scale(1.02)',
    // },
  }));

  return (
    <Container className="my-5 p-5 registration-container">
      <Box display="flex" justifyContent="center" alignItems="center">
        <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
            <Box>
              <Box className="registration-progress-container text-center">
                {regTabs.map((item) => (
                  <div key={item.value}>
                    <Box className={`registration-progress-bubble mx-4 ${step === item.value ? 'active' : step > item.value ? 'completed' : ''}`}>{step > item.value ? '✔' : item.value}</Box>
                    <Typography component="legend" className="my-2">
                      {' '}
                      {item.label}
                    </Typography>
                  </div>
                ))}
              </Box>
              <form onSubmit={handleSubmit}>
                {step === 1 && (
                  <Box component="fieldset">
                    {/* <Typography component="legend">
                      <b>Company Details</b>
                    </Typography> */}
                    <TextField label="Company Name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required fullWidth />
                    <FormControl component="fieldset" fullWidth margin="normal">
                      <Typography>Number of employees, including you</Typography>
                      <RadioGroup name="companySize" value={companySize} onChange={(e) => setCompanySize(e.target.value)}>
                        {companySizes.map((size, index) => (
                          <FormControlLabel key={index} value={size} control={<Radio />} label={size} />
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <FormControl fullWidth>
                      <InputLabel>Location</InputLabel>
                      <Select value={location} label="License" onChange={(e) => setLocation(e.target.value)}>
                        {allCountries.map((country, index) => (
                          <MenuItem key={index} value={country}>
                            {country}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button variant="contained" color="primary" className="mt-3" onClick={nextStep} disabled={!companyName || !companySize || !location}>
                      Next
                    </Button>
                  </Box>
                )}

                {step === 2 && (
                  <Box component="fieldset">
                    {/* <Typography component="legend">
                      <b>User Details</b>
                    </Typography> */}
                    <TextField label="Full Name" value={userName} onChange={(e) => setUserName(e.target.value)} required fullWidth />
                    <TextField label="Job Title" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} required fullWidth margin="normal" />
                    <TextField
                      label="Phone Number"
                      type="tel"
                      value={phone}
                      onChange={handlePhoneChange}
                      error={phoneError}
                      helperText={phoneError && 'Invalid Phone Number'}
                      required
                      fullWidth
                      margin="normal"
                    />
                    <Button variant="contained" color="primary" onClick={nextStep} disabled={!userName || !jobTitle || !phone}>
                      Next
                    </Button>
                    &nbsp;
                    <Button variant="outlined" color="info" onClick={prevStep}>
                      Back
                    </Button>
                  </Box>
                )}

                {step === 3 && (
                  <Box component="fieldset">
                    {/* <FormControl fullWidth>
                      <InputLabel>License</InputLabel>
                      <Select value={license} label="License" onChange={(e) => setLicense(e.target.value)}>
                        {licenseProducts.map((product, index) => (
                          <MenuItem key={index} value={product}>
                            {product}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl> */}

                    {/* <Grid container spacing={2}>
                          {licenseProducts.map((lc) => (
                            <Grid item xs={12} sm={4} key={lc.value}>
                              <StyledCard
                                isSelected={license === lc.value}
                                onClick={() => setLicense(lc.value)}
                                sx={{
                                  color: 'white',
                                  bgcolor: lc.bgcolor,
                                  border: license === lc.value ? '3px solid black' : 'none',
                                }}
                              >
                                <CardHeader
                                  sx={{
                                    height: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                  }}
                                >
                                  <Typography variant="h6" component="div" align="center">
                                    {lc.label}
                                  </Typography>
                                </CardHeader>
                                <CardContent>
                                  {lc.features.map((feature, i) => (
                                    <div key={i}>{feature}</div>
                                  ))}
                                </CardContent>
                              </StyledCard>
                            </Grid>
                          ))}
                        </Grid> */}
                    <Grid container spacing={2}>
                      {licenseProducts.map((lc) => (
                        <Grid item xs={12} sm={4} className="d-flex flex-grow-1" key={lc.value}>
                          <StyledCard
                            className="d-flex flex-column flex-grow-1"
                            onClick={() => setLicense(lc.value)}
                            sx={{
                              border: license === lc.value ? '3px solid black' : 'none',
                            }}
                          >
                            <CardHeader
                              sx={{
                                color: 'white',
                                bgcolor: lc.bgcolor,
                                fontSize: '14px',
                                textAlign: 'center',
                                '& .MuiCardHeader-content': {
                                  width: '100%',
                                },
                              }}
                              title={<Typography component="div">{lc.label}</Typography>}
                            />
                            <CardContent
                              sx={{
                                fontSize: '12px',
                              }}
                            >
                              {lc.features.map((feature, i) => (
                                <Typography key={i} component="div" sx={{ mb: 1 }}>
                                  • {feature}
                                </Typography>
                              ))}
                            </CardContent>
                          </StyledCard>
                        </Grid>
                      ))}
                    </Grid>

                    <Button variant="contained" color="primary" className="mt-5" onClick={nextStep} disabled={!license}>
                      Next
                    </Button>
                    <Button variant="outlined" color="info" className="mt-5 mx-2" onClick={prevStep}>
                      Back
                    </Button>
                  </Box>
                )}

                {step === 4 && (
                  <Box component="fieldset">
                    {/* <Typography component="legend">
                      <b>Verification</b>
                    </Typography> */}
                    <TextField
                      label="Sign in Email"
                      type="email"
                      value={signInEmail}
                      onChange={handleSignInEmailChange}
                      error={signInEmailError}
                      helperText={signInEmailError && 'Invalid email'}
                      required
                      fullWidth
                    />
                    <TextField label="Password" type="password" value={password} onChange={handlePasswordChange} required fullWidth margin="normal" />
                    <p className="link" onClick={() => setIsPasswordPopupOpen(true)}>
                      View Password Requirements
                    </p>
                    <Dialog open={isPasswordPopupOpen} onClose={() => setIsPasswordPopupOpen(false)}>
                      <DialogTitle>Password Requirements</DialogTitle>
                      <DialogContent>
                        <List>
                          <ListItem>
                            <ListItemText primary="Lowercase letter" secondary={isLowercase ? '✔' : '✖'} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Uppercase letter" secondary={isUppercase ? '✔' : '✖'} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Digit" secondary={isDigit ? '✔' : '✖'} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="Special character" secondary={isSpecialChar ? '✔' : '✖'} />
                          </ListItem>
                          <ListItem>
                            <ListItemText primary="8-15 characters" secondary={isLengthValid ? '✔' : '✖'} />
                          </ListItem>
                        </List>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setIsPasswordPopupOpen(false)} color="primary">
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                    <ReCAPTCHA sitekey="6LfmbRkTAAAAAFe9WOdrSVqwWyZdqqjQ7CpVK5J3" onChange={handleCaptchaChange} />
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      className="mt-2"
                      disabled={!captchaVerified || !signInEmail || !password || !isLowercase || !isUppercase || !isDigit || !isSpecialChar || !isLengthValid}
                    >
                      Register and start free trial
                    </Button>
                    {errorMessage && <Typography color="error">{errorMessage}</Typography>}
                    <br />
                    <Typography variant="body2" className="mt-2">
                      By clicking Agree and continue, you agree to the ERP Agreement and Supplemental Terms and Conditions for Raya AI Free Trial Agreement.
                    </Typography>
                    <Button variant="outlined" color="info" className="mt-2" onClick={prevStep}>
                      Back
                    </Button>
                  </Box>
                )}

                {step === 5 && (
                  <Box component="fieldset">
                    <Typography component="legend">
                      <b>Scan QR Code</b>
                    </Typography>
                    <Typography>Use any Authenticator app to scan the QR code below</Typography>
                    <Box className="qr-code-container" display="flex" justifyContent="center" my={2}>
                      {qrCodeImage ? <img src={`data:image/png;base64,${qrCodeImage}`} alt="TOTP QR Code" /> : <CircularProgress />}
                    </Box>
                    <Typography>After scanning, use the generated code to complete your login.</Typography>
                    <TextField
                      label="Verification Code"
                      value={verificationCode}
                      onChange={(e) => {
                        const value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
                        setVerificationCode(value);
                        if (value.length === 6) {
                          handleVerifyCode(value);
                        }
                      }}
                      inputProps={{ maxLength: 6 }}
                      required
                      fullWidth
                      margin="normal"
                    />
                    <Button variant="contained" color="primary" onClick={handleVerifyCode} disabled={verificationCode.length !== 6}>
                      Verify Code
                    </Button>
                  </Box>
                )}
              </form>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box className="registration-right mx-2">
              <Typography variant="h4">30 Days Free Trial</Typography>
              <Typography>Signing up for your trial</Typography>
              <List>
                <ListItem>
                  <ListItemText primary="Quickly access all ERP features." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Operate from any location and on any device." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Manage your business securely online." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Share and back up files with a terabyte of cloud storage." />
                </ListItem>
                <ListItem>
                  <ListItemText primary="Enjoy free support 24/7." />
                </ListItem>
              </List>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export const EmailVerification = () => {
  return (
    <Box className="email-verification">
      <Typography variant="h4">Check Your Email</Typography>
      <Typography>Please check your email to set up your login password and sign in to your account.</Typography>
    </Box>
  );
};
