import React from 'react';
import { Container, Row, Col, ListGroup, Form, Button } from 'react-bootstrap';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

export const UnAuthWebsiteFooter = () => {
    return (
        <footer className="text-light py-4" style={{ backgroundColor: 'rgb(3, 46, 97)' }}>
            <Container fluid>
                <Row>
                    <Col md={3}>
                        <h5>About Raya AI Suite</h5>
                        <p>Raya AI Suite offers comprehensive ERP/CRM solutions powered by advanced AI to streamline your business operations.</p>
                    </Col>
                    <Col md={3}>
                        <h5>Quick Links</h5>
                        <ListGroup variant="flush">
                        <ListGroup.Item as="a" href="/about" className="bg-transparent border-0 p-0 mb-2 text-white">About Us</ListGroup.Item>
                            <ListGroup.Item as="a" href="/contact" className="bg-transparent border-0 p-0 mb-2 text-white">Contact</ListGroup.Item>
                            <ListGroup.Item as="a" href="/privacy" className="bg-transparent border-0 p-0 text-white">Privacy Policy</ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col md={3}>
                        <h5>Follow Us</h5>
                        <div>
                            <a href="https://facebook.com" className="text-white me-2"><FaFacebook /></a>
                            <a href="https://twitter.com" className="text-white me-2"><FaTwitter /></a>
                            <a href="https://linkedin.com" className="text-white me-2"><FaLinkedin /></a>
                            <a href="https://instagram.com" className="text-white"><FaInstagram /></a>
                        </div>
                    </Col>
                    <Col md={3}>
                        <h5>Newsletter</h5>
                        <Form>
                            <Form.Group controlId="newsletterEmail">
                                <Form.Control type="email" placeholder="Enter email" />
                            </Form.Group>
                            <Button variant="outline-primary" type="submit" className="mt-2">Subscribe</Button>
                        </Form>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="text-center">
                        © {new Date().getFullYear()} Raya AI Suite. All rights reserved.
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}
