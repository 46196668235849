import React from 'react';
import { Container, Row, Col, Accordion } from 'react-bootstrap';
import Lottie from 'react-lottie';
import faqAnimation from './faq.json'; // Path to your animation file
import './FAQ.css';

const faqs = [
  {
    question: 'How can ERP benefit my business?',
    answer: 'ERP systems streamline business processes by automating routine tasks and providing real-time insights, which improves efficiency and decision-making.',
  },
  {
    question: 'What advantages does CRM offer?',
    answer: 'CRM helps businesses manage customer relationships more effectively, leading to better customer satisfaction, retention, and sales growth.',
  },
  {
    question: 'How does SCM improve operations?',
    answer: 'Supply Chain Management (SCM) optimizes the flow of products, information, and finances, enhancing the overall efficiency and reducing costs.',
  },
  {
    question: 'Is this product free?',
    answer: 'Yes, our ERP, CRM, and SCM solutions are available for free for 30 days with all essential features included.',
  },
  {
    question: 'How secure is my data?',
    answer: 'We implement best-in-class security and compliance measures to ensure that your data is protected at all times.',
  }
];

const FAQSection = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: faqAnimation,
  };

  return (
    <Container className="faq-section mt-5">
      <Row className="align-items-center">
        <Col lg={6} className="d-flex justify-content-center mb-4 mb-lg-0">
          <Lottie options={defaultOptions} height={463} width={590} />
        </Col>
        <Col lg={6}>
          <h3 className="text-center mb-4">Frequently Asked Questions</h3>
          <Accordion defaultActiveKey="0">
            {faqs.map((faq, idx) => (
              <Accordion.Item eventKey={idx.toString()} key={idx}>
                <Accordion.Header>{faq.icon} {faq.question}</Accordion.Header>
                <Accordion.Body>{faq.answer}</Accordion.Body>
              </Accordion.Item>
            ))}
          </Accordion>
        </Col>
      </Row>
    </Container>
  );
};

export default FAQSection;
