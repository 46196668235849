import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import '../forgot-password/ForgotPassword.css';
import { Form, InputGroup, Card, Alert, Modal, ListGroup } from "react-bootstrap";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { API_BASE_URL, API_CREDENTIALS_ENDPOINT, API_RESET_PASSWORD } from "../../constants/constants";

export const ResetPassword = () => {
    const resetPasswordApi = `${API_BASE_URL}${API_CREDENTIALS_ENDPOINT}${API_RESET_PASSWORD}`;
    const location = useLocation();
    const navigate = useNavigate();
    const [token, setToken] = useState('');
    const [isValidToken, setIsValidToken] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [showPasswordModal, setShowPasswordModal] = useState(false);

    const [isLowercase, setIsLowercase] = useState(false);
    const [isUppercase, setIsUppercase] = useState(false);
    const [isDigit, setIsDigit] = useState(false);
    const [isSpecialChar, setIsSpecialChar] = useState(false);
    const [isLengthValid, setIsLengthValid] = useState(false);
    const [doPasswordsMatch, setDoPasswordsMatch] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    useEffect(() => {
        const query = new URLSearchParams(location.search);
        const tokenParam = query.get("token");
        if (tokenParam) {
            setToken(tokenParam);
            // Validate token locally
            validateToken(tokenParam);
        } else {
            setErrorMessage("Invalid request. Token is missing.");
        }
    }, [location.search]);

    useEffect(() => {
        validatePassword(newPassword);
    }, [newPassword]);

    useEffect(() => {
        setDoPasswordsMatch(newPassword === confirmPassword);
    }, [confirmPassword, newPassword]);

    const validateToken = (token) => {
        try {
            const decodedToken = jwtDecode(token);
            const currentTime = Date.now() / 1000; // Current time in seconds
            if (decodedToken.exp > currentTime) {
                setIsValidToken(true);
            } else {
                setErrorMessage("Token has expired. Please request a new password reset.");
            }
        } catch (error) {
            setErrorMessage("Invalid request. Please request a new password reset.");
        }
    };

    const validatePassword = (password) => {
        const lowercase = /[a-z]/.test(password);
        const uppercase = /[A-Z]/.test(password);
        const digit = /\d/.test(password);
        const specialChar = /[@$!%*?&]/.test(password);
        const lengthValid = password.length >= 8 && password.length <= 15;

        setIsLowercase(lowercase);
        setIsUppercase(uppercase);
        setIsDigit(digit);
        setIsSpecialChar(specialChar);
        setIsLengthValid(lengthValid);

        const passwordValid = lowercase && uppercase && digit && specialChar && lengthValid;
        setIsPasswordValid(passwordValid);

        return lowercase && uppercase && digit && specialChar && lengthValid;
    };

    const handleNewPasswordChange = (e) => {
        setNewPassword(e.target.value);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
    };

    const handleSubmit = async () => {
        if (!newPassword || !confirmPassword) {
            setErrorMessage("Password cannot be null.");
            return;
        }

        if (!doPasswordsMatch) {
            setErrorMessage("Passwords do not match.");
            return;
        }

        if (!validatePassword(newPassword)) {
            setShowPasswordModal(true);
            return;
        }

        try {
            const response = await axios.post(resetPasswordApi, 
                { password: newPassword },
                {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }
            );
            if (response.status === 200) {
                setSuccessMessage("Password has been reset successfully.");
                setErrorMessage('');
                // Optionally redirect after success
                setTimeout(() => {
                    navigate("/login");
                }, 3000);
            }
        } catch (error) {
            setErrorMessage("Something went wrong. Please try again later.");
            setSuccessMessage('');
        }
    };


    return (
        <div className="login-container">
            <Card className="login-card">
                <Card.Body className="login-card-body" style={{ backgroundColor: 'white' }}>
                    <div className="login-form">
                        <h5 className="login-title">Reset Password</h5>
                        <br />
                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        {successMessage && <Alert variant="success">{successMessage}</Alert>}
                        {isValidToken ? (
                            <>
                                <Form.Group className="mb-4">
                                    <InputGroup>
                                        <Form.Control
                                            value={newPassword}
                                            onChange={handleNewPasswordChange}
                                            placeholder="New Password"
                                            type="password"
                                            size="lg"
                                            style={!isPasswordValid ? { border: '2px solid red' } : {}}
                                            className={`login-input ${!isPasswordValid ? 'invalid-input' : ''}`}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <Form.Group className="mb-4">
                                    <InputGroup>
                                        <Form.Control
                                            value={confirmPassword}
                                            onChange={handleConfirmPasswordChange}
                                            placeholder="Confirm Password"
                                            type="password"
                                            size="lg"
                                            style={!doPasswordsMatch ? { border: '2px solid red' } : {}}
                                            className={`login-input ${!doPasswordsMatch ? 'invalid-input' : ''}`}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                <p className="password-validation" onClick={() => setShowPasswordModal(true)}><a href='#'>Password requirements</a></p>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className="login-button"
                                    onClick={handleSubmit}
                                    disabled={!isPasswordValid || !doPasswordsMatch}
                                >
                                    Reset Password
                                </Button>
                                {successMessage && (
                                    <div>
                                        <br />
                                        <p>
                                            <b>Redirecting to login</b>
                                            <span className="dot dot-1">.</span>
                                            <span className="dot dot-2">.</span>
                                            <span className="dot dot-3">.</span>
                                        </p>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div>
                                <p>Return to <a href="/login">login</a></p>
                            </div>
                        )}
                    </div>
                </Card.Body>
            </Card>
            <Modal show={showPasswordModal} onHide={() => setShowPasswordModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Password Validation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Password must be 8-15 characters long and include:</p>
                    <ListGroup>
                        <ListGroup.Item>
                            <span>Lowercase letter</span> <span>{isLowercase ? "✔" : "✖"}</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <span>Uppercase letter</span> <span>{isUppercase ? "✔" : "✖"}</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <span>Digit</span> <span>{isDigit ? "✔" : "✖"}</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <span>Special character</span> <span>{isSpecialChar ? "✔" : "✖"}</span>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <span>8-15 characters</span> <span>{isLengthValid ? "✔" : "✖"}</span>
                        </ListGroup.Item>
                    </ListGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowPasswordModal(false)}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
