import axios from 'axios';
import { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Button, Container, Card, Form, InputGroup, FormControl } from 'react-bootstrap';
import { BsEnvelope, BsKey } from 'react-icons/bs';
import Lottie from 'react-lottie';
import loadingAnimation from './Loading.json';
import './LoginPage.css';
import { API_BASE_URL, API_AUTH_ENDPOINT, API_VERIFY_MFA_ENDPOINT, DASHBOARD_APP_URI, DOMAIN, TOKEN_EMAIL_CLAIMS, TOKEN_USER_GIVEN_NAME, BI_APP_URI } from '../../constants/constants';
import { useToken } from '../useToken';
import { CircularProgress } from '@mui/material';
import { Lock } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

export const LoginPage = ({ authSession, onLogin }) => {
  const navigate = useNavigate();
  const [token, setToken] = useToken();
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [signedInEmail, setSignedInEmail] = useState('');
  const [signedInName, setSignedInName] = useState('');
  const [requireMfa, setRequireMfa] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [qrCodeImage, setQrCodeImage] = useState('');

  const loginEndpoint = API_BASE_URL + API_AUTH_ENDPOINT;
  const verifyMfaEndpoint = API_BASE_URL + API_AUTH_ENDPOINT + API_VERIFY_MFA_ENDPOINT;

  const getPayloadFromToken = (token) => {
    const encodedPayload = token.split('.')[1];
    return JSON.parse(atob(encodedPayload));
  };

  useEffect(() => {
    const storedToken = localStorage.getItem('authToken') || Cookies.get('authToken');
    if (storedToken) {
      if (getPayloadFromToken(storedToken).exp > Date.now() / 1000) {
        const decodedToken = getPayloadFromToken(storedToken);
        setSignedInEmail(decodedToken[TOKEN_EMAIL_CLAIMS]);
        setSignedInName(decodedToken[TOKEN_USER_GIVEN_NAME]);
      }
    }
  }, []);

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const handleLogin = async () => {
    const loginEndpoint = API_BASE_URL + API_AUTH_ENDPOINT;
    setIsLoading(true);
    try {
      const response = await axios.post(loginEndpoint, {
        emailAddress: email,
        password: password,
      });
      const { token, requireMfa, qrCodeImageAsBase64 } = response.data;
      if (requireMfa) {
        setRequireMfa(true);
        setErrorMessage('');
      } else if (qrCodeImageAsBase64) {
        setQrCodeImage(qrCodeImageAsBase64);
        setErrorMessage('');
      } else {
        setToken(token);
        if (rememberMe) {
          localStorage.setItem('authToken', token);
        }
        Cookies.set('authToken', token, { domain: DOMAIN, secure: true, sameSite: 'None', path: '/' });
        setErrorMessage('');
        onLogin(true);
        await delay(2000);
        navigateUserBasedOnLicense();
      }
    } catch (error) {
      setErrorMessage(error.response?.status === 401 ? 'Invalid Email ID or Password' : 'An error has occurred');
    } finally {
      setIsLoading(false);
    }
  };

  const handleVerifyCode = async (code) => {
    if (code.length !== 6) {
      setErrorMessage('Verification code must be 6 digits long.');
      return;
    }

    try {
      setIsLoading(true);
      const response = await axios.post(verifyMfaEndpoint, {
        emailAddress: email,
        totpCode: code,
      });

      if (response.status === 200) {
        const { token } = response.data;
        setToken(token);
        Cookies.set('authToken', token, { domain: DOMAIN, secure: true, sameSite: 'None', path: '/' });
        // window.location.href = `${DASHBOARD_APP_URI}dashboard?token=${token}`;
        navigateUserBasedOnLicense();
      } else {
        setErrorMessage('Invalid code. Please try again.');
      }
    } catch (error) {
      console.error('An error occurred while verifying the code:', error);
      setErrorMessage('Two-factor authentication failed.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUseAnotherAccount = () => {
    localStorage.removeItem('authToken');
    Cookies.remove('authToken', { domain: DOMAIN, path: '/' });
    setSignedInEmail('');
    setEmail('');
    setPassword('');
  };

  const redirectToApp = () => {
    if (token) {
      navigateUserBasedOnLicense();
    } else {
      // Redirect to login page
      handleUseAnotherAccount();
    }
  };

  const decodeJWT = (token) => {
    try {
      return jwtDecode(token);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  };

  const navigateUserBasedOnLicense = () => {
    const decodedToken = decodeJWT(token);
    if (decodedToken?.RayaLicense === 'BIAndBS') {
      navigate('/raya-products');
    } else if (decodedToken?.RayaLicense === 'BI') {
      window.location.href = `${BI_APP_URI}bi/dashboard?token=${token}`;
    } else if (decodedToken?.RayaLicense === 'BS') {
      window.location.href = `${DASHBOARD_APP_URI}dashboard?token=${token}`;
    }
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loadingAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!email) {
        setErrorMessage('Email is required');
      } else if (!validateEmail(email)) {
        setErrorMessage('Invalid email ID');
      } else if (!password) {
        setErrorMessage('Password is required');
      } else {
        handleLogin();
      }
    }
  };

  return (
    <Container
      fluid
      className="login-container"
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      {isPageLoading ? (
        <CircularProgress className="page-spinner" />
      ) : (
        <Card className="login-card" style={{ textAlign: 'center' }}>
          <Card.Body style={{ background: 'white' }}>
            {isLoading ? (
              <div className="loading-container">
                <Lottie options={defaultOptions} height={150} width={150} />
                <p className="loading-text">Signing you in. Just a moment...</p>
              </div>
            ) : requireMfa || qrCodeImage ? (
              <div className="mfa-container">
                <div>
                  <Lock></Lock>
                  <br />
                  <h5 className="text-center mb-4">Two factor authentication</h5>
                </div>
                {qrCodeImage && (
                  <div className="qr-code-container" style={{ marginBottom: '20px' }}>
                    <p>Use Authenticator app to scan the QR code</p>
                    <img src={`data:image/png;base64,${qrCodeImage}`} alt="TOTP QR Code" style={{ height: '100px', width: '100px' }} />
                  </div>
                )}
                <Form.Group className="mb-4">
                  <FormControl
                    type="tel"
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    placeholder="Verification Code"
                    size="lg"
                    maxLength="6"
                    onInput={(e) => {
                      const value = e.target.value?.replace(/\D/g, '');
                      setVerificationCode(value);
                      if (value.length === 6) {
                        handleVerifyCode(value);
                      }
                    }}
                    className={`login-input ${errorMessage ? 'is-invalid' : ''}`}
                  />
                  {errorMessage && <Form.Text className="text-danger">{errorMessage}</Form.Text>}
                </Form.Group>
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <Button className="text-center" onClick={handleVerifyCode} variant="primary" disabled={!verificationCode}>
                    Verify
                  </Button>
                )}
                <br />
                <a href="/forgot-password" className="link">
                  Recover account
                </a>
              </div>
            ) : signedInEmail ? (
              <div className="signed-in-container">
                <div className="account-details" style={{ backgroundColor: '#f8f9fa', padding: '1rem', borderRadius: '5px', cursor: 'pointer' }} onClick={redirectToApp}>
                  <span className="account-icon">👤 {signedInName}</span>
                  <span className="account-email">{signedInEmail}</span>
                  <p>Signed in</p>
                </div>
                <hr />
                <p onClick={handleUseAnotherAccount} className="link link-secondary use-another-account">
                  Logout and use another account
                </p>
              </div>
            ) : (
              <div>
                <h2>Login</h2>
                <Form.Group className="mb-4">
                  <InputGroup>
                    <InputGroup.Text>
                      <BsEnvelope />
                    </InputGroup.Text>
                    <FormControl value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Address" type="email" size="lg" className="login-input" onKeyPress={handleKeyPress} />
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-4">
                  <InputGroup>
                    <InputGroup.Text>
                      <BsKey />
                    </InputGroup.Text>
                    <FormControl value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" type="password" size="lg" className="login-input" onKeyPress={handleKeyPress} />
                  </InputGroup>
                </Form.Group>
                <div className="d-flex justify-content-between">
                  <Form.Group className="mb-4">
                    <Form.Check type="checkbox" label="Remember me" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} className="text-center" />
                  </Form.Group>
                  <a href="/forgot-password" className="link link-secondary">
                    <i>Forgot Password?</i>
                  </a>
                </div>
                {errorMessage && <p className="text-danger">{errorMessage}</p>}
                <Button className="text-center" onClick={handleLogin} size="lg" variant="primary" disabled={!email || !password}>
                  Login
                </Button>
                <hr className="my-4" />
                <a href="#" className="link link-secondary" onClick={() => navigate('/register')}>
                  Register
                </a>
              </div>
            )}
          </Card.Body>
        </Card>
      )}
    </Container>
  );
};
