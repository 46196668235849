import React from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FiPhone } from 'react-icons/fi';

const ContactUs = () => {
  const navigate = useNavigate();

  return (
    <Container className="mt-5 text-center" id="contact-us">
      <Row>
        <Col>
          <Button variant='outline-grey' onClick={() => navigate('contact-us')} >
            <FiPhone /> Contact Us
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default ContactUs;
