import { Button } from "@mui/material";
import './ForgotPassword.css';
import { Form, InputGroup, Card, Alert } from "react-bootstrap";
import { BsEnvelope } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import axios from "axios";
import { API_AUTH_ENDPOINT, API_BASE_URL, API_FORGOT_PASSWORD } from "../../constants/constants";
import { useNavigate } from "react-router-dom";

export const ForgotPassword = () => {
    const navigate = useNavigate();
    const forgotPasswordApi = `${API_BASE_URL}${API_AUTH_ENDPOINT}${API_FORGOT_PASSWORD}`;
    const [email, setEmail] = useState('');
    const [captchaVerified, setCaptchaVerified] = useState(false);
    const [emailValid, setEmailValid] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const handleCaptchaChange = (value) => {
        setCaptchaVerified(true);
    };

    const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    const handleEmailChange = (e) => {
        const emailValue = e.target.value;
        setEmail(emailValue);

        // Basic email validation
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        setEmailValid(emailPattern.test(emailValue));
    };

    const handleSubmit = async () => {
        try {
            const emailBody = { "EmailAddress": email };
            const response = await axios.post(forgotPasswordApi, emailBody);
            if (response.status === 200) {
                setSuccessMessage("Password reset link sent to your email address.");
                setErrorMessage('');
                await delay(4000);
                navigate('/login');
            }
        } catch (error) {
            setErrorMessage("Something went wrong. Please try again later.");
            setSuccessMessage('');
        }
    };

    return (
        <div className="login-container">
            <Card className="login-card">
                <Card.Body className="login-card-body" style={{ backgroundColor: 'white' }}>
                    <div className="login-form">
                        <h5 className="login-title">Recover Account</h5>
                        <p className="login-description">Enter your email address and we'll send you a link to reset your password.</p>
                        {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
                        {successMessage && 
                            <div>
                                <Alert variant="success">{successMessage}</Alert>
                                <p><b>Redirecting to login</b>
                                    <span className="dot dot-1">.</span>
                                    <span className="dot dot-2">.</span>
                                    <span className="dot dot-3">.</span>
                                </p>                            
                            </div>
                        }
                        {!successMessage &&
                            <div>
                                <Form.Group className="mb-4">
                                    <InputGroup>
                                        <InputGroup.Text><BsEnvelope /></InputGroup.Text>
                                        <Form.Control
                                            value={email}
                                            onChange={handleEmailChange}
                                            placeholder="Email"
                                            type="email"
                                            size="lg"
                                            className={`login-input ${emailValid ? 'valid' : ''}`}
                                        />
                                    </InputGroup>
                                </Form.Group>
                                
                                <ReCAPTCHA
                                    sitekey="6LfmbRkTAAAAAFe9WOdrSVqwWyZdqqjQ7CpVK5J3"
                                    onChange={handleCaptchaChange}
                                    className="recaptcha"
                                />
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    className="login-button"
                                    disabled={!captchaVerified || !emailValid}
                                    onClick={handleSubmit}
                                >
                                    Forgot Password
                                </Button>
                            </div>
                        }
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}
