import React from 'react';
import { Container, Row, Col, Carousel, Accordion } from 'react-bootstrap';
import { Line, Doughnut, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { FaChartBar, FaCogs, FaLightbulb, FaChartPie, FaNetworkWired, FaDatabase } from 'react-icons/fa';
import './BusinessIntelligence.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    LineElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const features = [
    {
        title: 'Cross-Platform Analytics',
        description: 'Gain insights from multiple ERPs through a unified platform.',
        icon: <FaNetworkWired size={50} />,
    },
    {
        title: 'AI-Driven Insights',
        description: 'Leverage AI to uncover hidden trends and opportunities.',
        icon: <FaLightbulb size={50} />,
    },
    {
        title: 'Custom Dashboards',
        description: 'Create dashboards tailored to your business needs.',
        icon: <FaChartBar size={50} />,
    },
    {
        title: 'Data Integration',
        description: 'Seamlessly integrate data from any ERP or third-party tool.',
        icon: <FaDatabase size={50} />,
    },
    {
        title: 'Predictive Analytics',
        description: 'Predict future outcomes with advanced analytics.',
        icon: <FaCogs size={50} />,
    },
    {
        title: 'Interactive Reports',
        description: 'Generate and share interactive reports with your team.',
        icon: <FaChartPie size={50} />,
    },
];

const groupedFeatures = features.reduce((result, feature, index) => {
    const groupIndex = Math.floor(index / 3);
    if (!result[groupIndex]) {
        result[groupIndex] = [];
    }
    result[groupIndex].push(feature);
    return result;
}, []);

const BusinessIntelligence = () => {
    const lineChartData = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June'],
        datasets: [{
            label: 'Revenue Growth',
            data: [12000, 15000, 13000, 17000, 18000, 21000],
            fill: false,
            borderColor: '#4A90E2',
            tension: 0.1,
        }],
    };

    const doughnutChartData = {
        labels: ['ERP A', 'ERP B', 'ERP C', 'ERP D'],
        datasets: [{
            data: [30, 20, 25, 25],
            backgroundColor: ['#FF5722', '#8BC34A', '#FFC107', '#03A9F4'],
            borderColor: '#ffffff',
            borderWidth: 2,
        }],
    };

    const barChartData = {
        labels: ['Q1', 'Q2', 'Q3', 'Q4'],
        datasets: [{
            label: 'Cost Reduction',
            data: [10, 15, 12, 20],
            backgroundColor: '#8BC34A',
            borderRadius: 5,
        }],
    };

    return (
        <Container fluid className="bi-container">
            <Row className="text-center mb-5">
                <Col>
                    <h1 className="display-4 title">Business Intelligence</h1>
                    <p className="lead description">Gain powerful insights, no matter what ERP you use, with Raya's AI-driven BI tool.</p>
                </Col>
            </Row>

            <div className="feature-carousel-wrapper slide-in">
                <Carousel className="feature-carousel" indicators={false}>
                    {groupedFeatures.map((group, idx) => (
                        <Carousel.Item key={idx}>
                            <Row className="justify-content-center">
                                {group.map((feature, index) => (
                                    <Col md={4} key={index}>
                                        <div className="feature-card">
                                            <div className="feature-icon">{feature.icon}</div>
                                            <h2>{feature.title}</h2>
                                            <p>{feature.description}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>

            <div className="text-center my-4">
                <h2>Unlock the full potential of your data with our AI-powered business intelligence tools.</h2>
            </div>

            <Row className="mb-5 charts-section">
                <Col md={6} className="mb-4">
                    <div className="chart-container">
                        <Line data={lineChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
                <Col md={6} className="mb-4">
                    <div className="chart-container">
                        <Doughnut data={doughnutChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
            </Row>

            <Row className="mb-5">
                <Col md={6} className="mb-4 offset-md-3">
                    <div className="chart-container">
                        <Bar data={barChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
            </Row>

            <Row className="text-center benefits-section">
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Informed Decisions</h3>
                        <p>Make data-driven decisions with confidence.</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Improved Efficiency</h3>
                        <p>Streamline your business processes with actionable insights.</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Enhanced Performance</h3>
                        <p>Boost your business performance with predictive analytics.</p>
                    </div>
                </Col>
            </Row>

            <Row className="faq-section slide-in">
                <Col>
                    <h2 className="text-center mb-4">Frequently Asked Questions</h2>
                    <Accordion defaultActiveKey="0" className="faq-accordion">
                        {[
                            { question: "Can I use Raya BI with any ERP?", answer: "Yes, Raya BI is designed to integrate seamlessly with any ERP system, allowing you to gain insights from your existing data." },
                            { question: "How does AI improve my business insights?", answer: "Our AI algorithms analyze your data to identify trends, forecast outcomes, and suggest optimizations tailored to your business needs." },
                            { question: "Can I create custom dashboards?", answer: "Absolutely. Raya BI allows you to create and customize dashboards that reflect the metrics most important to your business." },
                            { question: "Is Raya BI easy to implement?", answer: "Yes, our team provides full support during implementation, ensuring that Raya BI is quickly and efficiently integrated into your operations." }
                        ].map((faq, index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                                <Accordion.Header>{faq.question}</Accordion.Header>
                                <Accordion.Body>{faq.answer}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
};

export default BusinessIntelligence;
