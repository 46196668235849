import React from 'react';
import { Container, Row, Col, Carousel, Accordion } from 'react-bootstrap';
import { Doughnut, Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { FaHeadset, FaTicketAlt, FaClipboardCheck, FaHandshake, FaRobot, FaChartLine } from 'react-icons/fa';
import './ServiceManagement.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    ArcElement,
    Title,
    Tooltip,
    Legend
);

const features = [
    {
        title: 'Customer Service',
        description: 'Provide top-notch support to your customers with our integrated tools.',
        icon: <FaHeadset size={50} />,
    },
    {
        title: 'Ticketing System',
        description: 'Efficiently manage and resolve customer issues with our robust ticketing system.',
        icon: <FaTicketAlt size={50} />,
    },
    {
        title: 'Observations & Feedback',
        description: 'Capture and act on customer feedback to continuously improve services.',
        icon: <FaClipboardCheck size={50} />,
    },
    {
        title: 'Vendor Management',
        description: 'Streamline vendor interactions and maintain strong relationships.',
        icon: <FaHandshake size={50} />,
    },
    {
        title: 'AI-Powered Chatbots',
        description: 'Deploy intelligent chatbots to handle customer inquiries 24/7.',
        icon: <FaRobot size={50} />,
    },
    {
        title: 'Service Analytics',
        description: 'Gain insights into service performance with real-time data.',
        icon: <FaChartLine size={50} />,
    },
];

const groupedFeatures = features.reduce((result, feature, index) => {
    const groupIndex = Math.floor(index / 3);
    if (!result[groupIndex]) {
        result[groupIndex] = [];
    }
    result[groupIndex].push(feature);
    return result;
}, []);

const ServiceManagement = () => {
    const barChartData = {
        labels: ['Resolved', 'Pending', 'Escalated'],
        datasets: [{
            label: 'Ticket Status Distribution',
            data: [120, 30, 10],
            backgroundColor: ['#4A90E2', '#FFC107', '#FF5722'],
            borderRadius: 5,
        }],
    };

    const doughnutChartData = {
        labels: ['Positive', 'Neutral', 'Negative'],
        datasets: [{
            data: [75, 15, 10],
            backgroundColor: ['#8BC34A', '#FFC107', '#FF5722'],
            borderColor: '#ffffff',
            borderWidth: 2,
        }],
    };

    const barChartDataVendor = {
        labels: ['Vendor A', 'Vendor B', 'Vendor C', 'Vendor D'],
        datasets: [{
            label: 'Vendor Performance Rating',
            data: [85, 70, 90, 75],
            backgroundColor: '#8BC34A',
            borderRadius: 5,
        }],
    };

    return (
        <Container fluid className="service-container">
            <Row className="text-center mb-5">
                <Col>
                    <h1 className="display-4 title">Service Management</h1>
                    <p className="lead description">Enhance your customer service operations with our comprehensive service management tools.</p>
                </Col>
            </Row>

            <div className="feature-carousel-wrapper slide-in">
                <Carousel className="feature-carousel" indicators={false}>
                    {groupedFeatures.map((group, idx) => (
                        <Carousel.Item key={idx}>
                            <Row className="justify-content-center">
                                {group.map((feature, index) => (
                                    <Col md={4} key={index}>
                                        <div className="feature-card">
                                            <div className="feature-icon">{feature.icon}</div>
                                            <h2>{feature.title}</h2>
                                            <p>{feature.description}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>

            <div className="text-center my-4">
                <h2>Deliver exceptional service and boost customer satisfaction with our advanced solutions.</h2>
            </div>

            <Row className="mb-5 charts-section">
                <Col md={6} className="mb-4">
                    <div className="chart-container">
                        <Bar data={barChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
                <Col md={6} className="mb-4">
                    <div className="chart-container">
                        <Doughnut data={doughnutChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
            </Row>

            <Row className="mb-5">
                <Col md={6} className="mb-4 offset-md-3">
                    <div className="chart-container">
                        <Bar data={barChartDataVendor} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
            </Row>

            <Row className="text-center benefits-section">
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Enhanced Customer Satisfaction</h3>
                        <p>Improve customer relationships with fast, reliable support.</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Streamlined Operations</h3>
                        <p>Manage customer service tasks efficiently and effectively.</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Data-Driven Decisions</h3>
                        <p>Use insights from service analytics to make informed decisions.</p>
                    </div>
                </Col>
            </Row>

            <Row className="faq-section slide-in">
                <Col>
                    <h2 className="text-center mb-4">Frequently Asked Questions</h2>
                    <Accordion defaultActiveKey="0" className="faq-accordion">
                        {[
                            { question: "How can I set up a ticketing system?", answer: "Our platform provides an easy-to-use interface to set up and manage your ticketing system, ensuring all customer issues are tracked and resolved." },
                            { question: "Can I integrate chatbots with my existing customer service?", answer: "Yes, our AI-powered chatbots can be seamlessly integrated with your current customer service operations to handle inquiries around the clock." },
                            { question: "How does vendor management work?", answer: "Our vendor management tools allow you to monitor vendor performance, manage contracts, and maintain strong relationships with your key suppliers." },
                            { question: "Is the system scalable for large teams?", answer: "Absolutely. Our service management solution is designed to scale with your business, accommodating growing customer service needs and complex operations." }
                        ].map((faq, index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                                <Accordion.Header>{faq.question}</Accordion.Header>
                                <Accordion.Body>{faq.answer}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
};

export default ServiceManagement;
