import { UnAuthWebsiteFooter } from './website/website-footer/UnAuthWebsiteFooter';
import { UnAuthHeader } from './website/website-header/UnAuthHeader';
import { RayaRoutes } from './Routes';
import { useCallback, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { useToken } from './auth/useToken';
import { API_AUTH_RENEW_TOKEN, API_BASE_URL } from './constants/constants';
import axios from 'axios';
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [token, setToken] = useToken();
  const renewSessionEndpoint = API_BASE_URL + API_AUTH_RENEW_TOKEN;
  const sessionTimeout = 15 * 60 * 1000; 
  const [lastActivity, setLastActivity] = useState(Date.now());

  const logOutFunc = () => {
    window.location.href = '/login';
    localStorage.removeItem('token');
    localStorage.removeItem('showWelcomeAlert');
  };

  const handleRenewSession = async () => {
    if (token) {
      const config = {
        headers: { Authorization: `Bearer ${token}` }
      };
  
      try {
        const renewResponse = await axios.post(renewSessionEndpoint, {
          token: token
        }, config);
    
        setToken(renewResponse.data.token);
      } catch (error) {
        console.error('Error renewing session:', error);
      }
    }
  }

  const checkTokenExpiry = useCallback(() => {
    const token = localStorage.getItem('token');

    if (token && token !== 'undefined')
    {
      const decodedToken = decodeJWT(token);
      const tokenExpiry = decodedToken.exp;
      const tokenExpiryMilliseconds = tokenExpiry * 1000;
      const currentTime = Date.now() / 1000;

      if (tokenExpiry < currentTime)
      {
        logOutFunc();
      }

      if (tokenExpiryMilliseconds && Date.now() >= tokenExpiryMilliseconds - 5 * 60 * 1000) {
        handleRenewSession();
      }
    }
    
  }, [handleRenewSession]);

  const handleActivity = useCallback(() => {
    setLastActivity(Date.now());
    checkTokenExpiry();
  }, [checkTokenExpiry]);

  function decodeJWT(token) {
    try {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const payload = decodeURIComponent(atob(base64).split('').map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));
  
      return JSON.parse(payload);
    } catch (error) {
      console.error('Error decoding token:', error);
      return null;
    }
  }

  useEffect(() => {
    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keydown', handleActivity);
    window.addEventListener('click', handleActivity);

    const interval = setInterval(() => {
      if (Date.now() - lastActivity >= sessionTimeout) {
      }
    }, 1000);

    return () => {
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keydown', handleActivity);
      window.removeEventListener('click', handleActivity);
      clearInterval(interval);
    };
  }, [lastActivity, handleActivity, sessionTimeout]);
  
  return (
    <>
      <BrowserRouter>
        <UnAuthHeader />
          <div>
              <RayaRoutes isAuthenticated={(status) => setIsAuthenticated(status)}/>
          </div>
        <UnAuthWebsiteFooter />
      </BrowserRouter>
    </>
  );
}

export default App;
