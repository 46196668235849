import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useSpring, animated, config } from 'react-spring';
import { FaBoxOpen, FaChartLine, FaBullhorn, FaHeadset, FaTasks, FaCog } from 'react-icons/fa';
import styled from 'styled-components';

const SolutionsSectionWrapper = styled.div`
  background: #343a40;
  color: #fff;
  padding: 6rem 0;
`;

const Title = styled(animated.h2)`
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 3rem;
  color: #fff;
`;

const SolutionCard = styled(animated.div)`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  }
`;

const IconWrapper = styled.div`
  font-size: 3rem;
  margin-bottom: 15px;
  color: #ffc107;
`;

const SolutionTitle = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 10px;
  color: #fff;
`;

const SolutionDescription = styled.p`
  font-size: 0.9rem;
  color: #adb5bd;
`;

const solutions = [
  { title: 'Inventory Management', description: 'Optimize stock levels and streamline your supply chain.', icon: <FaBoxOpen /> },
  { title: 'Finance & Accounting', description: 'Automate bookkeeping and generate accurate financial reports.', icon: <FaChartLine /> },
  { title: 'Sales & Marketing', description: 'Boost your sales performance with targeted campaigns.', icon: <FaBullhorn /> },
  { title: 'Customer Service', description: 'Enhance customer satisfaction with efficient support.', icon: <FaHeadset /> },
  { title: 'Project Management', description: 'Ensure timely delivery with effective project tracking.', icon: <FaTasks /> },
  { title: 'Operations Management', description: 'Improve overall efficiency with streamlined operations.', icon: <FaCog /> },
];

const SolutionItem = ({ solution, index }) => {
  const springProps = useSpring({
    from: { opacity: 0, transform: 'translateY(50px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    delay: index * 100,
    config: config.wobbly,
  });

  return (
    <Col lg={4} md={6} className="mb-4">
      <SolutionCard style={springProps}>
        <IconWrapper>{solution.icon}</IconWrapper>
        <SolutionTitle>{solution.title}</SolutionTitle>
        <SolutionDescription>{solution.description}</SolutionDescription>
      </SolutionCard>
    </Col>
  );
};

const SolutionsSection = () => {
  const titleSpring = useSpring({
    from: { opacity: 0, transform: 'translateY(50px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
    config: config.molasses,
  });

  return (
    <SolutionsSectionWrapper>
      <Container>
        <Title style={titleSpring}>
          Our Innovative Solutions
        </Title>
        <Row>
          {solutions.map((solution, index) => (
            <SolutionItem key={index} solution={solution} index={index} />
          ))}
        </Row>
      </Container>
    </SolutionsSectionWrapper>
  );
};

export default SolutionsSection;
