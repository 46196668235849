import React from 'react';
import { Container, Row, Col, Carousel, Accordion } from 'react-bootstrap';
import { Doughnut, Radar, PolarArea } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    ArcElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { FaBalanceScale, FaFileInvoiceDollar, FaPiggyBank, FaCalculator, FaCoins, FaChartPie } from 'react-icons/fa';
import './FinanceAccounting.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    RadialLinearScale,
    ArcElement,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    Legend
);

const features = [
    {
        title: 'Automated Invoicing',
        description: 'Generate and send invoices automatically, reducing manual work.',
        icon: <FaFileInvoiceDollar size={50} />,
    },
    {
        title: 'Expense Tracking',
        description: 'Track and categorize expenses for better financial control.',
        icon: <FaCalculator size={50} />,
    },
    {
        title: 'Profit & Loss Analysis',
        description: 'Get detailed insights into your profits and losses.',
        icon: <FaBalanceScale size={50} />,
    },
    {
        title: 'Cash Flow Management',
        description: 'Monitor and manage your cash flow effectively.',
        icon: <FaPiggyBank size={50} />,
    },
    {
        title: 'Budgeting Tools',
        description: 'Plan and manage your budgets with ease.',
        icon: <FaCoins size={50} />,
    },
    {
        title: 'Financial Reporting',
        description: 'Generate comprehensive financial reports.',
        icon: <FaChartPie size={50} />,
    },
];

const groupedFeatures = features.reduce((result, feature, index) => {
    const groupIndex = Math.floor(index / 3);
    if (!result[groupIndex]) {
        result[groupIndex] = [];
    }
    result[groupIndex].push(feature);
    return result;
}, []);

const FinanceAccounting = () => {
    const doughnutChartData = {
        labels: ['Revenue', 'Expenses', 'Profit'],
        datasets: [{
            data: [22000, 15000, 7000],
            backgroundColor: ['#4CAF50', '#FF5722', '#FFC107'],
            borderColor: '#ffffff',
            borderWidth: 2,
        }],
    };

    const radarChartData = {
        labels: ['Q1', 'Q2', 'Q3', 'Q4'],
        datasets: [{
            label: 'Quarterly Financial Performance',
            data: [75, 85, 70, 90],
            backgroundColor: 'rgba(76, 175, 80, 0.2)',
            borderColor: '#4CAF50',
            pointBackgroundColor: '#4CAF50',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: '#4CAF50',
        }],
    };

    const polarAreaChartData = {
        labels: ['Salaries', 'Rent', 'Utilities', 'Miscellaneous'],
        datasets: [{
            data: [12000, 9000, 8000, 5000],
            backgroundColor: ['#FFC107', '#03A9F4', '#8BC34A', '#FFEB3B'],
            borderColor: '#ffffff',
            borderWidth: 2,
        }],
    };

    return (
        <Container fluid className="finance-container">
            <Row className="text-center mb-5">
                <Col>
                    <h1 className="display-4 title">Finance & Accounting</h1>
                    <p className="lead description">Manage your financials with precision and gain actionable insights to grow your business.</p>
                </Col>
            </Row>

            <div className="feature-carousel-wrapper slide-in">
                <Carousel className="feature-carousel" indicators={false}>
                    {groupedFeatures.map((group, idx) => (
                        <Carousel.Item key={idx}>
                            <Row className="justify-content-center">
                                {group.map((feature, index) => (
                                    <Col md={4} key={index}>
                                        <div className="feature-card">
                                            <div className="feature-icon">{feature.icon}</div>
                                            <h2>{feature.title}</h2>
                                            <p>{feature.description}</p>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>

            <div className="text-center my-4">
                <h2>Take control of your finances with our comprehensive accounting tools and insights.</h2>
            </div>

            <Row className="mb-5 charts-section">
                <Col md={6} className="mb-4">
                    <div className="chart-container">
                        <Doughnut data={doughnutChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
                <Col md={6} className="mb-4">
                    <div className="chart-container">
                        <Radar data={radarChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
            </Row>

            <Row className="mb-5">
                <Col md={6} className="mb-4 offset-md-3">
                    <div className="chart-container">
                        <PolarArea data={polarAreaChartData} options={{ responsive: true, maintainAspectRatio: false }} />
                    </div>
                </Col>
            </Row>

            <Row className="text-center benefits-section">
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Accuracy</h3>
                        <p>Ensure your financial data is always accurate and up-to-date.</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Efficiency</h3>
                        <p>Streamline financial processes to save time and reduce errors.</p>
                    </div>
                </Col>
                <Col md={4} className="mb-4">
                    <div className="benefit-item">
                        <h3>Scalability</h3>
                        <p>Grow your business without outgrowing your accounting tools.</p>
                    </div>
                </Col>
            </Row>

            <Row className="faq-section slide-in">
                <Col>
                    <h2 className="text-center mb-4">Frequently Asked Questions</h2>
                    <Accordion defaultActiveKey="0" className="faq-accordion">
                        {[
                            { question: "How does automated invoicing work?", answer: "Our system automatically generates invoices based on your sales data and sends them to your clients, ensuring timely payments." },
                            { question: "Can I track expenses across multiple departments?", answer: "Yes, our expense tracking feature allows you to categorize and monitor expenses for each department, giving you full financial control." },
                            { question: "What financial reports can I generate?", answer: "You can generate a variety of reports, including profit & loss statements, balance sheets, cash flow statements, and custom reports tailored to your business needs." },
                            { question: "Is the system secure?", answer: "Absolutely. Our platform uses advanced encryption and security measures to protect your financial data at all times." }
                        ].map((faq, index) => (
                            <Accordion.Item eventKey={index.toString()} key={index}>
                                <Accordion.Header>{faq.question}</Accordion.Header>
                                <Accordion.Body>{faq.answer}</Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Col>
            </Row>
        </Container>
    );
};

export default FinanceAccounting;
