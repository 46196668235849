import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { FaRocket, FaChartLine, FaUsers, FaLock } from 'react-icons/fa';

const Benefits = () => {
  const benefits = [
    { icon: <FaRocket />, title: "Increased Efficiency", description: "Streamline operations and reduce manual tasks." },
    { icon: <FaChartLine />, title: "Better Decision Making", description: "Access real-time data for informed choices." },
    { icon: <FaUsers />, title: "Improved Collaboration", description: "Enhance teamwork with integrated communication tools." },
    { icon: <FaLock />, title: "Enhanced Security", description: "Protect your business data with advanced security features." },
  ];

  return (
    <Container className="mt-5">
      <h2 className="text-center mb-4">Benefits of using Raya in your business</h2>
      <Row>
        {benefits.map((benefit, idx) => (
          <Col key={idx} md={3} className="mb-4">
            <Card className="h-100 text-center">
              <Card.Body>
                <div className="benefit-icon mb-3" style={{ fontSize: '2rem', color: '#007bff' }}>
                  {benefit.icon}
                </div>
                <Card.Title>{benefit.title}</Card.Title>
                <Card.Text>{benefit.description}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Benefits;